import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageContainer from '../../../components/Container/pageContainer'
import { Main } from '../../../constant'
import { Box, Grid } from '@mui/material'
import { CustomSelect } from '../../../components/select/select'
import { Date } from '../../../components/Date/Date'
import { Input } from '../../../components/input/input'
import { CustomBtn } from '../../../components/button/button'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { SelectOnObject } from '../../../components/select/selectOnObject'
import FormTable from './components/table'
import CustomForm from '../../../components/form/form'
import { ShowLoader } from '../../../redux/actions/loader'

const StudentStatus = (props) => {
    const [ data , setData ] = useState(Main().Shoqa);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const url = useSelector((state) => state.Api);
    const [ update , setUpdate ] = useState()
    const [ selectClass , setSelectClass ] = useState()
    const [ parentShoqa , setParentShoqa ] = useState()
    const [termYear, setTermYear] = useState({ getTerms: '' });
    const [ serverData , setServerData ] = useState({
        ShowClass:'',
        result:''
    })
  const dispatch = useDispatch();



    const [ inputValues , setInputValues ] = useState({
        class:'',
        subject:'',
        teacher:''
    })
    useEffect(() => {
        try {
          axios.get(url.ShowClass, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              //console.log(res.data.message);
            //   setServerData({ShowClass:res.data.result});
            fetchResultYear()
            setServerData(
                (oldData)=> ({
                    ...oldData,
                    ShowClass:res.data.result}
              ));
            }
          })
          .catch((err) => {
            //console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          //console.error(err);
        }


        
      }, [update]);

      const extractNumber = (marksPercentage) => {
        const match = marksPercentage.match(/^(\d+)\/\d+/);
        return match ? parseInt(match[1], 10) : 0;
      }

    const ChangeOnSelect = async(getparam) => {
      console.log(getparam[0])
      fetchResultYear()
      setParentShoqa()

      setTermYear({ getTerms: '' })
      setSelectClass(getparam[0])
      setServerData(
        (oldData)=> ({
            ...oldData,
            result:''}
      ));
      return false
    }
    const fetchResult = () =>{
      dispatch(ShowLoader('1'))
    
      const payload = {
        Class:selectClass,
        parentShoqa: parentShoqa
      }
      try {
        axios.post(url.StudentStatus, payload, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res)

            let sortedData = res.data.result.sort((a, b) => extractNumber(b.MarksPercentage) - extractNumber(a.MarksPercentage));
            // console.log(sortedData)
            //console.log(res.data.message);
          //   setServerData({ShowClass:res.data.result});
          setTimeout(()=>{
            dispatch(ShowLoader('0'))
          },1000)

          const arr = JSON.stringify(termYear)
          const getArr = JSON.parse(arr)
          let getTermSrn = getArr.getTerms.find((item) => { return item.Srn == parentShoqa})
          getTermSrn.ClassID = selectClass
          sortedData.termSrn = getTermSrn
          console.log(selectClass, sortedData.termSrn)
          const dataAndTerm = {fetch: sortedData, term: getTermSrn}
          setServerData(
              (oldData)=> ({
                  ...oldData,
                  result:dataAndTerm,
                }
            ));
          }
        })
        .catch((err) => {
          dispatch(ShowLoader('0'))
          //console.error(err); // Log the error response for debugging
        });
      } catch (err) {
        //console.error(err);
      }
    }

    const ChangeTermsSelect = (param) =>{
      const termSrn = param[0]

      const arr = JSON.stringify(termYear)
      const getArr = JSON.parse(arr)
      const getTermSrn = getArr.getTerms.find((item) => { return item.Srn == termSrn})

      // setServerData(
      //   (oldData)=> ({
      //       ...oldData,
      //       getTerm: getTermSrn}
      // ));
      console.log(serverData)
      setParentShoqa(termSrn)
    }


    const fetchResultYear = async () => {
      try {
          dispatch(ShowLoader('1'))
          const yearResponse = await axios.get(url.resultYear, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${userToken}`,
              },
          });

          if (yearResponse.status === 200) { 
              dispatch(ShowLoader('0'))

              setTermYear(prevData => ({
                  ...prevData,
                  getTerms: yearResponse.data.result
              }));
          }
      } catch (err) {
          console.error('Error fetching result year:', err);
      }
      console.log(termYear)
  };

  const openGrading = () => {
    console.log( serverData )
    if(selectClass === undefined  || selectClass === ''){
      alert('please select Class')
      return false
    }
    else if( parentShoqa == undefined || parentShoqa == ''){
      alert('please select Term')
      return false
    }
    
    fetchResult()
  }
  return (
    <PageContainer title={data.title} description={data.description}>
        <Box>
            <Grid container>
                {
                    serverData.ShowClass && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option='ClassName' items={serverData.ShowClass} GetSelectedValue={(e)=>ChangeOnSelect(e)} name='Class' />
                            </Box>
                        </Grid>
                    )
                }
                {
                      termYear.getTerms && (

                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option="TermYear" items={termYear?.getTerms}  GetSelectedValue={(e)=>ChangeTermsSelect(e)} name='Term' />
                            </Box>
                        </Grid>
                            )
                    }
            </Grid>
            <Grid  item lg={6}>
                      <Box>
                        <CustomBtn type="button" click={()=>openGrading()} data=" Open Grade " />
                    </Box>
              </Grid>
              <br/>
            <Grid container>
                <Grid item lg={12}>
                    {/* <Box mt={4}> */}
                    {
                      serverData.result && serverData.result.length !== 0 && serverData.result != null && serverData.result != undefined && (
                        <>
                          <FormTable data={serverData.result} />
                        </>
                      )
                    }
                    {/* </Box> */}
                </Grid>
            </Grid>
        </Box>
    </PageContainer>
  )
}

StudentStatus.propTypes = {}

export default StudentStatus