import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageContainer from '../../../components/Container/pageContainer';
import { Main } from '../../../constant';
import { Box, Grid } from '@mui/material';
import { SelectOnObject } from '../../../components/select/selectOnObject';
import axios from 'axios';
import { useSelector } from 'react-redux';
import FormTable from './table';
import { CustomBtn } from '../../../components/button/button';

const StudentResult = (props) => {
    const [data, setData] = useState(Main().Shoqa);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const url = useSelector((state) => state.Api);
    const [selectYear, setSelectYear] = useState('');
    const [selectTerm, setSelectTerm] = useState('');
    const [selectClass, setSelectClass] = useState('');
    const [serverData, setServerData] = useState({ ShowClass: '', result: '' });
    const [termYear, setTermYear] = useState({ getTerms: '' });
    const [term, setTerm] = useState({ terms: '' });
    const [fetchMidTerm, setFetchMidTerm] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const classResponse = await axios.get(url.ShowClass, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`,
                    },
                });

                if (classResponse.status === 200) {
                    setServerData(prevData => ({
                        ...prevData,
                        ShowClass: classResponse.data.result
                    }));
                    await fetchResultYear();
                }
            } catch (err) {
                console.error('Error fetching class data:', err);
            }
        };

        fetchData();
    }, [url.ShowClass, userToken]);

    const fetchResultYear = async () => {
        try {
            const yearResponse = await axios.get(url.resultYear, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            });

            if (yearResponse.status === 200) {
                
                setTermYear(prevData => ({
                    ...prevData,
                    getTerms: yearResponse.data.result
                }));
                
            }
        } catch (err) {
            console.error('Error fetching result year:', err);
        }
    };

    const fetchResultByClass = async (termDetails) => {
        const payload = { ClassSrn: selectClass, ParentShoqa: termDetails.Srn, Term: termDetails.termSrn, fetchMidIfFinalTerm:termDetails.SrnOfPrvTermIfFinal };
        // console.log('Fetching with payload:', payload);
        // console.log(url)
        // return false
    
        try {
            const resultResponse = await axios.post(url.resultByClass, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            });
            console.log('Result response:', resultResponse);
            if (resultResponse.status === 200) {
                console.log(resultResponse.data)
                serverDateUpdate(resultResponse.data)
                // const sortedData = resultResponse.data.shoqa1.sort((a, b) => a.Position - b.Position);
                // if(selectYear.SrnOfPrvTermIfFinal !== 0 ){
                    
                // }
                // setServerData(prevData => ({
                //     ...prevData,
                //     result: sortedData
                // }));
            }
            console.log(serverData)
        } catch (err) {
            console.error('Error fetching result by class:', err.message);
            console.error('Error details:', err); // Detailed error information
        }
    };

    const serverDateUpdate = (fData) => { //fetched Data to be set over here
        // console.log(sortedData1)
        // console.log(sortedData2)
        // console.log(mergeAndSum(sortedData1, sortedData2))
        if(selectYear.SrnOfPrvTermIfFinal !== 0 ){
            fData.shoqa1.Term = 1
            fData.shoqa2.Term = 1
            console.log(fData)

            const sortedData1 = fData.shoqa1.sort((a, b) => a.Position - b.Position);
            const sortedData2 = fData.shoqa2.sort((a, b) => a.Position - b.Position);
            sortedData1.Term = 1
            sortedData2.Term = 1
            setServerData(prevData => ({
                ...prevData,
                result: mergeAndSum(sortedData1, sortedData2)
            }));    
        }
        else if(selectYear.SrnOfPrvTermIfFinal == 0 ){
            const sortedData1 = fData.shoqa1.sort((a, b) => a.Position - b.Position);
            sortedData1.Term = 0
            setServerData(prevData => ({
                ...prevData,
                result: sortedData1
            }));   
        }

        // const newObject = mergeAndSum(shoqa1, shoqa2);
    }
    


    function mergeAndSum(shoqa1, shoqa2) {
        // Create a map to hold the merged data
        const mergedMap = new Map();
    
        // Function to add or update data in the map
        const addToMap = (data, prefix) => {
            data.forEach(item => {
                const rollNum = item.StudentSrn;
                if (rollNum === undefined) return; // Skip if StudentSrn is not defined
    
                // Initialize the entry if not present
                if (!mergedMap.has(rollNum)) {
                    mergedMap.set(rollNum, {
                        StudentSrn: rollNum,
                        Absent1: 0,
                        Sick1: 0,
                        Absent2: 0,
                        Sick2: 0,
                        Present1: 0,
                        Leaves1: 0,
                        Present2: 0,
                        Leaves2: 0,
                        Position1: '',
                        TotalGainedNumbers1: '',
                        Status1: '',
                        Position2: '',
                        TotalGainedNumbers2: '',
                        Status2: '',
                        Term: 1,
                        marksDetail: {}
                    });
                }
    
                const entry = mergedMap.get(rollNum);
                const marksDetails = item.marksDetail || [];
    
                // Update Absent and Sick counts based on prefix
                if (prefix === 'shoqa1') {
                    entry.Absent1 += item.Absent || 0;
                    entry.Sick1 += item.Sick || 0;
                    entry.Present1 += item.Present || 0;
                    entry.Leaves1 += item.Leaves || 0;
                    entry.Position1 = item.Position || entry.Position;
                    entry.TotalGainedNumbers1 = item.TotalGainedNumbers || entry.TotalGainedNumbers;
                    entry.Status1 = item.Status || entry.Status;
                    entry.Term = 1;
                } else if (prefix === 'shoqa2') {
                    entry.Absent2 += item.Absent || 0;
                    entry.Sick2 += item.Sick || 0;
                    entry.Present2 += item.Present || 0;
                    entry.Leaves2 += item.Leaves || 0;
                    entry.Position2 = item.Position || entry.Position;
                    entry.TotalGainedNumbers2 = item.TotalGainedNumbers || entry.TotalGainedNumbers;
                    entry.Status2 = item.Status || entry.Status;
                    entry.Term = 1;
                }
    
                // Update marksDetail
                marksDetails.forEach(marksDetail => {
                    const srn = marksDetail.Srn;
                    if (srn === undefined) return; // Skip if Srn is not defined
    
                    if (!entry.marksDetail[srn]) {
                        entry.marksDetail[srn] = {
                            Oral1: 0,
                            Written1: 0,
                            Oral2: 0,
                            Written2: 0,
                            Name_P: ''
                        };
                    }
    
                    if (prefix === 'shoqa1') {
                        entry.marksDetail[srn].Oral1 += marksDetail.Oral || 0;
                        entry.marksDetail[srn].Written1 += marksDetail.Written || 0;
                        entry.marksDetail[srn].Name_P = marksDetail.Name_P || entry.marksDetail[srn].Name_P;
                        entry.marksDetail[srn].FName_P = marksDetail.FName_P || entry.marksDetail[srn].FName_P;
                        entry.marksDetail[srn].SubjectNamePashot = marksDetail.SubjectNamePashot || entry.marksDetail[srn].SubjectNamePashot;
                        entry.marksDetail[srn].ClassName_P = marksDetail.ClassName_P || entry.marksDetail[srn].ClassName_P;
                    } else if (prefix === 'shoqa2') {
                        entry.marksDetail[srn].Oral2 += marksDetail.Oral || 0;
                        entry.marksDetail[srn].Written2 += marksDetail.Written || 0;
                        entry.marksDetail[srn].Name_P = marksDetail.Name_P || entry.marksDetail[srn].Name_P;
                        entry.marksDetail[srn].FName_P = marksDetail.FName_P || entry.marksDetail[srn].FName_P;
                        entry.marksDetail[srn].SubjectNamePashot = marksDetail.SubjectNamePashot || entry.marksDetail[srn].SubjectNamePashot;
                        entry.marksDetail[srn].ClassName_P = marksDetail.ClassName_P || entry.marksDetail[srn].ClassName_P;
                    }
                });
            });
        };
    
        // Process both shoqa1 and shoqa2
        addToMap(shoqa1, 'shoqa1');
        addToMap(shoqa2, 'shoqa2');
    
        // Convert the map to an array and format the results
        const result = Array.from(mergedMap.values()).map(entry => {
            console.log(entry)
            const formattedMarksDetail = {};
            for (const [srn, details] of Object.entries(entry.marksDetail)) {
                formattedMarksDetail[srn] = {
                    Oral1: details.Oral1,
                    Written1: details.Written1,
                    Oral2: details.Oral2,
                    Written2: details.Written2,
                    Name_P: details.Name_P,
                    FName_P: details.FName_P,
                    SubjectNamePashot: details.SubjectNamePashot,
                    ClassName_P: details.ClassName_P
                };
            }
            return {
                StudentSrn: entry.StudentSrn,
                Absent1: entry.Absent1,
                Sick1: entry.Sick1,
                Absent2: entry.Absent2,
                Sick2: entry.Sick2,
                Present1: entry.Present1,
                Leaves1: entry.Leaves1,
                Present2: entry.Present2,
                Leaves2: entry.Leaves2,
                Position1: entry.Position1,
                Position2: entry.Position2,
                TotalGainedNumbers1: entry.TotalGainedNumbers1,
                TotalGainedNumbers2: entry.TotalGainedNumbers2,
                Status1: entry.Status1,
                Status2: entry.Status2,
                Term: 1,
                marksDetail: formattedMarksDetail
            };
        });
    
        // Debug output to verify correct aggregation
        console.log('Merged Data:', result);
    
        return result;
    }
    
    
    
    const ChangeOnSelect = (getparam) => {
        setSelectClass(getparam[0]);
    };
 

    
    const ChangeTermsSelect = (param) =>{
        const termSrn = param[0]
  console.log(param)
        const arr = JSON.stringify(termYear)
        const getArr = JSON.parse(arr)
        // console.log(getArr)
        const getTermSrn = getArr.getTerms.find((item) => { return item.Srn == termSrn})
        setSelectYear(getTermSrn)
        console.log(getTermSrn.TermSrn)
        // setParentShoqa(termSrn)
    }

    const fetchResult = () =>{
        // console.log(selectYear)
        fetchResultByClass(selectYear)
    }

    return (
        <PageContainer title={data.title} description={data.description}>
            <Box>
                <Grid container>
                    {serverData.ShowClass && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject 
                                    option='ClassName' 
                                    items={serverData.ShowClass} 
                                    GetSelectedValue={ChangeOnSelect} 
                                    name='Class' 
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    <Grid item lg={6}>
                        <Box p={1}>
                        {
                      termYear.getTerms && (
 
                            <Box p={1}>
                                <SelectOnObject option="TermYear" items={termYear?.getTerms}  GetSelectedValue={(e)=>ChangeTermsSelect(e)} name='Term' />
                            </Box> 
                            )
                    }
                            {/* <SelectOnObject 
                                option='year' 
                                value={selectYear} 
                                items={termYear.years} 
                                GetSelectedValue={ChangeYear} 
                                name='Year' /> */}
                        </Box>
                    </Grid>
                    
                     
                </Grid>
                <Grid container>
                    
                    <Grid  item lg={6}>
                            <Box>
                                <CustomBtn type="button" click={()=>fetchResult()} data=" Open Grade " />
                            </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={12}>
                        {serverData.result && serverData.result.length !== 0 && (
                            <>
                                <FormTable key={0} data={serverData.result} />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </PageContainer>
    );
};

StudentResult.propTypes = {};

export default StudentResult;
