import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CustomForm from '../../../../components/form/form'
import { Main } from '../../../../constant';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PostRequest } from '../../../../redux/actions/PostRequest';
import { GetRequest } from '../../../../redux/actions/GetRequest';
import { UpdateOwnState } from '../../../../redux/actions/state/state';

const CreateClass = props => {
    const [data , setData] = useState(Main().subject);

    const dispatch = useDispatch();
    const getfilterdata =  data.inputs.filter((item) => item.feildtype !== 'label');
    const initialInputValues = Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    );
      const url = useSelector((state)=>state.Api.CreateClass)
      const geturl = useSelector((state) => state.Api.ShowClass);

    const [inputValues, setInputValues] = useState(initialInputValues);
    
    function handleInputChange(e) {
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
    }
     function submitForm(event){
        event.preventDefault();
        console.log(inputValues)
      const userToken = JSON.parse(sessionStorage.getItem('User_Data')).token;
        dispatch(PostRequest(url, userToken, inputValues))
        dispatch(GetRequest(geturl, userToken, 'SHOW_CLASSES'));
        dispatch(UpdateOwnState())
        
    }
    const ChangeDate = (e , name) =>{
        console.log(e , name)
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: e
    }));
    }
  return (
    <>
    <form onSubmit={submitForm}>
        <Grid container>
            <CustomForm ChangeDate={ChangeDate} data={data.CreateSubjects} handleInputChange={handleInputChange} />
        </Grid>
    </form>
    </>
  )
}

CreateClass.propTypes = {}

export default CreateClass