import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Main } from '../../../../../constant';
import { Input } from '../../../../../components/input/input';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import RadioButn from '../../../../../components/radioButn/radioButn';
import { CustomBtn } from '../../../../../components/button/button';
import CustomForm from '../../../../../components/form/form';
import { StudentRegistation } from '../../../../../redux/actions/registration/studentregistation';
import { useDispatch, useSelector } from 'react-redux';
import { UserData } from '../../../../../hooks/FirstTimeWebSrn/Websrn';
import { DateGlobalConvertor } from '../../../../../components/Date/DateGlobalConverter'
import dayjs from 'dayjs';

const Auth = (props) => {
    const [data, setData] = useState(Main().Registration);
    const dispatch = useDispatch();
    const api = useSelector((state) => state.Api)
    const getfilterdata = data.inputs.filter((item) => item.feildtype !== 'label');
    const [loadDate, setLoadDate] = useState(DateGlobalConvertor(dayjs(Date.now())))
    const initialInputValues = Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    );
    //console.log(initialInputValues)
    const [inputValues, setInputValues] = useState(initialInputValues);

    const [butnVisiablity, setButnVisiablity] = useState(true)
    const [formKey, setFormKey] = useState(0);

    function handleInputChange(e) {
        if (inputValues.AdmissionDate === '') { 
            updateAdmDate()
        }
        console.log(inputValues)
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
    }

    function handleRadioChange(e, chk) {
        // data.inputs.forEach((field, index) => {
        //     if (field.feildtype === 'radio') {
        //         if (field.name === e.target.name && field.data === 'Yes') {
        //             field.check = true;
        //             hostelFound = true;
        //         } else if (field.name === e.target.name && field.data === 'No') {
        //             if (hostelFound) {
        //                 field.check = false;
        //                 hostelFound = false;
        //             } else {
        //                 field.check = true;
        //             }
        //         }
        //     }
        // });
        console.log(e, chk)
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.placeholder,
        }));
        // e.target.checked == !e.target.checked
        console.log(inputValues)
    }
    const ChangeOnSelect = (getparam) => {
        setInputValues((oldData) => ({
            ...oldData,
            [getparam[2]]: getparam[3],
            [getparam[1]]: getparam[0],
        })
        )
    }

    const myfunc = async (event) => {
        event.preventDefault();
        console.log(inputValues)
        await setButnVisiablity(false)
        await dispatch(StudentRegistation(api.StudentRegistation, JSON.parse(sessionStorage.getItem("User_Data")).token, JSON.stringify(inputValues)))
        setTimeout(() => {
            refreshForm();
            setInputValues(initialInputValues)
            setButnVisiablity(true)
        }, 1000)
    }
    
    function updateAdmDate(){
        setInputValues((prevValues) => ({
            ...prevValues,
            'AdmissionDate': loadDate.formatNormalDate,
            'admGrDate': loadDate.gregorianDate,
            'admArDate': loadDate.arabicDate,
        }));
        console.log(inputValues)
    }

    const ChangeDate = (e, name, grDate, arDate) => {
        const grDateConst = name != 'DOB' ? 'admGrDate' : 'dobGrDate';
        const arDateConst = name != 'DOB' ? 'admArDate' : 'dobArDate';
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: e,
            [grDateConst]: grDate,
            [arDateConst]: arDate
        }));
    }
    // Function to refresh the form
    const refreshForm = () => {
        setFormKey(prevKey => prevKey + 1);
    }
    
    return (
        <form onSubmit={myfunc}>
            <Grid container >
                <CustomForm key={formKey} data={data.inputs} ChangeOnSelect={ChangeOnSelect} ChangeDate={ChangeDate} handleRadioChange={handleRadioChange} handleInputChange={handleInputChange} />
            </Grid>
            <Grid container mt={1}>
                <Grid lg={3} item>
                    <Box>

                    </Box>
                </Grid>
                <Grid lg={6} item>
                    <Box>
                        <CustomBtn disable={!butnVisiablity} data={data.butn} style={{ margin: 'auto', display: 'block', marginTop: '10px' }} />
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

Auth.propTypes = {};

export default Auth;


