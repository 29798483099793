import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import CustomForm from '../../../../components/form/form';
import { Main } from '../../../../constant';
import { ShowSubjects } from '../../../../redux/actions/subject/showsubject';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import UpdateForm from './form';

const Selectclass = (props) => {
    const [data , setData] = useState(Main().subject);
    const [inputValues, setInputValues] = useState({Srn:''});
    const dispatch = useDispatch();
    const url = useSelector((state)=>state.Api.ShowSubject)
    const [showSubjects , setShowSubjects] = useState('');
    const [mainclass , setMainclass] = useState('');
    // console.log(subject , )
    // const func = props.Current;

    const myfunc = async (getparam1) => {
      console.log(inputValues)
      const getid = getparam1 == 'old' ? inputValues.Srn : getparam1[0];
        try {
          const userToken = JSON.parse(sessionStorage.getItem('User_Data')).token;
    
          const res = await axios.get(`${url}/${getid}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`
            },
            // params: inputValues,
          });
    
          // Assuming res.data.message is an array
          setShowSubjects(res.data.message);
          console.log(res.data.message);
        } catch (err) {
          console.error(err.response);
        }
      };
    
    const ChangeOnSelect = (getparam1) => {
      props.Current(getparam1[0])
      setMainclass(getparam1[0])
        // console.log([getparam1[1]],getparam1[0])
        setInputValues((oldData)=> ({
            ...oldData,
            [getparam1[1]]:getparam1[0]
        })
        )
          myfunc(getparam1);
    }
    console.log(showSubjects)
  return (
    <>
        <form>
            <Grid container mt={6}> 
                <CustomForm data={data.inputs} 
                ChangeOnSelect={(e)=>ChangeOnSelect(e)} 
                // handleInputChange={handleInputChange} 
                />
            </Grid>
        </form>
        {showSubjects  && (
            showSubjects.map((item, index) => 
                <div key={index}>
                    <UpdateForm func={()=>myfunc('old')} data={item} />
                </div>
            )
            )}
    </>
  )
}

Selectclass.propTypes = {}

export default Selectclass