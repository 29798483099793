import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageContainer from '../../../../components/Container/pageContainer'
import { Box, Grid, Typography } from '@mui/material'
import CustomForm from '../../../../components/form/form'
import { Main } from '../../../../constant'
import { CustomBtn } from '../../../../components/button/button'
// import SuggestionInput from '../../../../components/suggestionInput/suggestionInput'
import TeacherSuggestion from '../../../../components/suggestionInput/teachersuggestion'
import { Date } from '../../../../components/Date/Date'
import dayjs from 'dayjs'
import CustomTable from '../../../../components/table/SimpleTable'
import { Input } from '../../../../components/input/input'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { FullDate } from '../../../../components/Date/FullDate'
import { PostRequest } from '../../../../redux/actions/PostRequest'
import { CustomSelect } from '../../../../components/select/select'
import TeacherReportComponent from '../../../../components/report/teacher'
import { ShowLoader } from '../../../../redux/actions/loader'

const Salary = (props) => {
    const [data , setData] = useState(Main().Fee);
    const [getfilterdata , setGetfilterdata ] = useState(data.inputs.filter((item) => item.feildtype !== 'label'));
    const [initialInputValues , setInitialInputValues] = useState(Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    ));
    const [suggestions , setSuggestions] = useState();
    const dispatch = useDispatch();
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const url = useSelector((state) => state.Api);
    const [update , setUpdate] = useState(0);
    const [ studentOldDetails , setStudentOldDetails ] = useState()
    const [maxSelectableDate , setMaxSelectableDate] = useState(); 




    const [inputValues, setInputValues] = useState({Date: dayjs().format('MMMM YYYY'), ...initialInputValues});

    const fetchstudent = (param) => {
      dispatch(ShowLoader('1'))
        axios.post(url.showoldsalaryDetails, param,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data.message);
              setStudentOldDetails(res.data);
              setMaxSelectableDate(dayjs(res.data.lastrecord))
              dispatch(ShowLoader('0'))
            }
          })
          .catch((err) => {
            console.error(err); // Log the error response for debugging
          });
    }
    function suggestionhandleInputChange(e) {
        setStudentOldDetails('')
        // console.log(e.target.value)
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
        const RollNum = { RollNum: e.target.value}
        fetchstudent(RollNum)   
    }
    const ChangeDate = (e) =>{
        // console.log(`${e.$y}-${e.$M}-${e.$D}`)
        console.log( `${e.$y}-${e.$M+1}-${e.$D}`)
        setInputValues((prevValues) => ({
            ...prevValues,
            Month: `${e.$y}-${e.$M+1}-${e.$D}`,
          }));
          
    }
    const submitfunc = async(e) =>{
        e.preventDefault();
        const payload = {...inputValues}
        await dispatch(PostRequest(url.AddSalary , userToken , payload))
        setUpdate((update)=>update+1)
        const RollNum = {RollNum : inputValues.RollNum}
        fetchstudent(RollNum)
    }
    useEffect(() => {
        try {
          axios.get(url.teachersuggestion, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data.message);
              setSuggestions(res.data.message);
            }
          })
          .catch((err) => {
            console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          console.error(err);
        }
      }, [update]);

      const handleChange = (event) => {
        // console.log(event.target.value)
        setInputValues((prevValues) => ({
            ...prevValues,
            Month: event.target.value,
        }));
        // props.GetSelectedValue([event.target.value, event.target.name]);
      };
      function handleInputChange(e) {
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
    }

    const ClearDues = () =>{
        console.log('clicked')
        dispatch(PostRequest(url.clearSalary , userToken , {RollNum : studentOldDetails.result[0].RollNum}))
        setUpdate((update)=>update+1)
        fetchstudent({RollNum : studentOldDetails.result[0].RollNum})   
    }

  return (
    <PageContainer title="Student Fee" description="Stduent Fee">
        <form onSubmit={submitfunc}>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TeacherSuggestion 
                        handleInputChange={suggestionhandleInputChange}
                        Suggestions={suggestions}
                        placeholder='Roll No / Name'
                        name='RollNum'
                        />
                           {
                            studentOldDetails ? studentOldDetails.result && (
                                <>
                                    <Grid container>
                                      <Grid item lg={12}>
                                              <Box mt={1}>
                                                  Student MonthlyFee : 
                                                  <Typography sx={{color:'green'}} variant='b'>
                                                    {studentOldDetails.FeesDetials.MonthlyFee}
                                                  </Typography>
                                              </Box>
                                          </Grid>
                                        <Grid item lg={6}>
                                            <Box mt={1}>
                                                Teacher Old Dues : 
                                                <Typography sx={{color:'red'}} variant='b'>
                                                    {studentOldDetails.FeesDetials.reduceamount}
                                                    {/* {studentOldDetails.status[0]['Extra Paid']} */}
                                                  </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Box mt={1}>
                                                Extra Paid :
                                                 <Typography sx={{color:'green'}} variant='b'>
                                                    {studentOldDetails.status[0]['Extra Paid']}
                                                  </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Input sx={{margin:'15px 0px 0px'}} onChange={(event)=>handleInputChange(event)} name="Ammount" placeholder='Ammount' />
                                    <Grid container>
                                        {/* <Grid item lg={12}>
                                        {/* <Date sx={{width: '100%'}} /> */}

                                            {/* {
                                                maxSelectableDate && (
                                                    <FullDate throwfullevent={ChangeDate} name="Month" min={maxSelectableDate} width='100%' max={maxSelectableDate} />
                                                    )
                                                }
                                        </Grid> */} 
                                        {/* } */}

                                        
                                    </Grid>
                                    {/* <CustomSelect items={['jan','feb']} /> */}
                                    <Input sx={{margin:'15px 0px 0px'}} onChange={(event)=>handleInputChange(event)} name="comments" placeholder='Comments' />
                                    {/* <br /> */}
                                    <Box mt={1}></Box>
                                    <CustomBtn data='Submit' />
                                </>
                            )
                            :
                            null
                        }
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          {
                            studentOldDetails && (
                              <TeacherReportComponent data={studentOldDetails.TeacherDetails } />

                            )
                          }
                        </Grid>
                    </Grid>
                     
        </form>
        <Grid container>
            <Grid item lg={9} mt={4}>
                {
                    // studentOldDetails ? 
                    studentOldDetails && studentOldDetails.result.length != 0 && studentOldDetails.result != null && studentOldDetails.result != undefined  ?
                    <CustomTable data={studentOldDetails.result} />            
                    :
                    null
                }
            </Grid> 
        </Grid>
    </PageContainer>
  )
}

Salary.propTypes = {}

export default Salary