// ReportCard.js
import React from 'react';
import getImage from '../../../assets/images/newLogo.png'
import getMarifImage from '../../../assets/images/Marif.png'
import percentageSheet from '../../../assets/images/percentageSheet.png' //
// import './reportCard.css'; // Ensure this CSS file is correctly referenced
const percentage = (rec) => {
    const record = rec?.marksDetail;
    let sumOral = 0;   // Initialize sumOral to 0
    let sumWritten = 0; // Initialize sumWritten to 0

    // Loop through each object in the array
    record.forEach(item => {
        sumOral += parseInt(item.Oral) || 0; // Ensure to handle non-numeric values
        sumWritten += parseInt(item.Written) || 0; // Ensure to handle non-numeric values
    });

    const sumBoth = sumOral + sumWritten; // You can omit parseInt here as sumOral and sumWritten are numbers now
    const percentage = ((sumBoth*100)/(record.length * 100)).toFixed(2)
    return convertToPashto(percentage)+ '٪';
}
const failPass = (value) => {
    if (value += '' && value != undefined && value != null) {
        const spliteIt = value.split('/')
        const percent = parseFloat(spliteIt[0]) * 100 / parseFloat(spliteIt[1])
        return percent < 40 ? 'ناکام' : 'کامیاب';
    }
    else {
        return true
    }
}
const sumUpMarks = (record) => {
    // console.log(record)
    let sumOral = 0;   // Initialize sumOral to 0
    let sumWritten = 0; // Initialize sumWritten to 0

    // Loop through each object in the array
    record.forEach(item => {
        sumOral += parseInt(item.Oral) || 0; // Ensure to handle non-numeric values
        sumWritten += parseInt(item.Written) || 0; // Ensure to handle non-numeric values
        console.log(item);
    });

    const sumBoth = sumOral + sumWritten; // You can omit parseInt here as sumOral and sumWritten are numbers now
    console.log(sumBoth);
    return convertToPashto(sumBoth);
}


const convertToPashto = (number) => {
    const arabicToPashtoMap = {
        '0': '۰', '1': '۱', '2': '۲', '3': '۳',
        '4': '۴', '5': '۵', '6': '۶', '7': '۷',
        '8': '۸', '9': '۹'
    };
    // {
    //     '0': 'صفر', '1': 'اول', '2': 'دوهم', '3': 'دریم',
    //     '4': 'څلورم', '5': 'پنځم', '6': 'شپږم', '7': 'اووم',
    //     '8': 'اتم', '9': 'نهم'
    // };
    // console.log(number)
    return number.toString().split('').map(digit => arabicToPashtoMap[digit] || digit).join('');
}

const ReportCard = ({ rec }) => {
    const webUrl = `${window.location.protocol}//${window.location.host}/public/`;
    const WebSrn = localStorage.getItem('WebSrn')
    // const logo 
    console.log(webUrl, WebSrn)
    console.log(rec?.Term)

    const record = Object.values(rec?.marksDetail);
    console.log(record[0].Oral)
    // Initialize sums
    let sumOral1 = 0;
    let sumOral2 = 0;
    let sumWritten1 = 0;
    let sumWritten2 = 0;

    // Loop through each object in the array
    record.forEach(item => {
        sumOral1 += item.Oral1;
        sumOral2 += item.Oral2;
        sumWritten1 += item.Written1;
        sumWritten2 += item.Written2;
    });

    return (
        <div class="">
            {/* <div class="top-border"></div>
        <div class="right-border"></div>
        <div class="bottom-border"></div>
        <div class="left-border"></div>
        <div class="top-left-corner"></div>
        <div class="top-right-corner"></div>
        <div class="bottom-left-corner"></div>
        <div class="bottom-right-corner"></div> */}

            <div className="report-card ">
                {/* <header className="report-card-header">
                <h1>Student Report Card</h1>
                <p>Date: {new Date().toLocaleDateString()}</p>
            </header> */}
                {/* <div className='tradeMark'><p>واصلي ټیکنالوژي انجینر تماس 0703131865</p></div> */}
                <table className='borderFull'>
                    <tr>
                        <td className='topLeft'>&nbsp;&nbsp;&nbsp;</td>
                        <td className='horizontal'></td>
                        <td className='topRight'>&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <tr>
                        <td className='leftVertical'></td>
                        <td className='mainData'>
                        <div className='header'>
                    <table className='logoTable'>
                        <tr>
                            <td>
                                {/* <img className="student-photo" src={`${webUrl}${WebSrn}/Logo.png`} alt="logo" /> */}
                                <img className="resultSchoolLogo" src={getImage} alt="logo" />
                            </td>
                        </tr>
                    </table>
                    <table className='headerTable'>
                        <tr>
                            <td>
                                دافغانستان اسلامي امارت
                            </td>
                        </tr>
                        <tr>

                            <td>
                                دپوهني وزارت
                            </td>

                        </tr>
                        <tr>
                            <td>
                                کندهار د پوهني ریاست
                            </td>

                        </tr>
                        <tr>

                            <td>
                                حکیم الأمت دارالعلوم <span>(اوله نارینه څانګه)</span>
                            </td>
                        </tr>
                    </table>

                    <table className='marifTable'>
                        <tr>
                            <td>
                                {/* <img className="student-photo" src={`${webUrl}${WebSrn}/Logo.png`} alt="logo" /> */}
                                <img className="resultSchoolLogo" src={getMarifImage} alt="logo" />
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='capsule'>
                    <table>
                        <tr>
                            <td> ۱۴۴۶-۱۴۴۵ هـ.ق تعليمي کال</td>
                            <td>اطلاعنامه</td>
                            <td>۱۴۰۳ هـ.ش تعليمي کال</td>
                        </tr>
                    </table>
                </div>

                <div className="report-card-body">
                    {/* <h2>Student Details</h2> */}
                    {rec?.Term !== 1 ?
                        <table className='firstTable firstColumn Mid'>
                            <tbody>
                                <tr>
                                    <td className='verticalAlignTop'>
                                        <table className='allSubsNum'>
                                            <tbody>
                                                <tr>
                                                    <th>مضامین</th>
                                                    <th>شپږ <br />میاشتنۍ</th>
                                                    <th>کلنۍ</th>
                                                    <th>مجموعه</th>
                                                </tr>
                                                {record?.map((item, ind) => (
                                                    <tr>
                                                        <td>{item?.SubjectNamePashot}</td>
                                                        <td>{convertToPashto(item?.Oral + item?.Written)}</td>
                                                        <td></td>
                                                        <td>{convertToPashto(item?.Oral + item?.Written)}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td>مجموعه</td>
                                                    <td>{sumUpMarks(record)}</td>
                                                    <td> </td>
                                                    <td>{sumUpMarks(record)}</td>
                                                </tr>
                                                <tr>
                                                    <td>دنمروسلنه</td>
                                                    <td>{percentage(rec)}</td>
                                                    <td> </td>
                                                    <td>{percentage(rec)}</td>
                                                </tr>
                                                <tr>
                                                    <td>تقدیرات</td>
                                                    <td>{rec?.Status}</td>
                                                    <td> </td>
                                                    <td>{rec?.Status}</td>
                                                </tr>
                                                <tr>
                                                    <td>په ټولګۍ کي درجه</td>
                                                    <td>{convertToPashto(rec?.Position)}</td>
                                                    <td> </td>
                                                    <td>{convertToPashto(rec?.Position)}</td>
                                                </tr>
                                                <tr>
                                                    <td>نتیجه</td>
                                                    <td>{failPass(rec?.TotalGainedNumbers)}</td>
                                                    <td> </td>
                                                    <td>{failPass(rec?.TotalGainedNumbers)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className='secondColumn verticalAlignTop'>
                                        <img className="percentageSheet" src={percentageSheet} alt="logo" />
                                    </td>
                                    <td className='thirdColumn verticalAlignTop'>
                                        <table>
                                            <tr>
                                                <td className='verticalAlignTop'>
                                                    <table className='personalInfo'>
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan={2}>د زده کوونکي پېژندنه</th>
                                                            </tr>
                                                            <tr>
                                                                <td>نوم</td>
                                                                <td>{record[0]?.Name_P}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>دپلارنوم</td>
                                                                <td>{record[0]?.FName_P}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>اساس نمبر</td>
                                                                <td>{convertToPashto(rec?.StudentSrn)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ټولګي</td>
                                                                <td>{record[0]?.ClassName_P}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <br />
                                                    <table className='colorTable attendance'>
                                                        <tbody>
                                                            <tr>
                                                                <td>دحاضرۍ <br /> ضوابط</td>
                                                                <td>سوب</td>
                                                                <td>ناسوب</td>
                                                                <td>مریض</td>
                                                                <td>رخصت</td>
                                                            </tr>
                                                            <tr>
                                                                <td>شپږمیاشتنۍ</td>
                                                                <td>{convertToPashto(rec?.Present)}</td>
                                                                <td>{convertToPashto(rec?.Absent)}</td>
                                                                <td>{convertToPashto(rec?.Sick)}</td>
                                                                <td>{convertToPashto(rec?.Leaves)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>کلنۍ</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>مجموعه</td>
                                                                <td>{convertToPashto(rec?.Present)}</td>
                                                                <td>{convertToPashto(rec?.Absent)}</td>
                                                                <td>{convertToPashto(rec?.Sick)}</td>
                                                                <td>{convertToPashto(rec?.Leaves)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <br />
                                                    <table className='attendance signature'>
                                                        <tbody>
                                                            <tr>
                                                                <td>لاسلیک ځای</td>
                                                                <td>شپږ<br /> میاشتنۍ</td>
                                                                <td>کلنۍ	</td>
                                                            </tr>
                                                            <tr>
                                                                <td>دټولګۍ نګران</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>ددارالعلوم مدیر</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>دزده کونکي ولي</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>ددارلعلوم مهر</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        :


                        //For final Year
                        <table className='firstTable Final'>
                            <tbody>
                                <tr>
                                    <td className='verticalAlignTop'>
                                        <table className='allSubsNum'>
                                            <tbody>
                                                <tr>
                                                    <th>مضامین</th>
                                                    <th>شپږ<br/>میاشتنۍ</th>
                                                    <th>کلنۍ</th>
                                                    <th>مجموعه</th>
                                                </tr>
                                                {record?.map((item, ind) => (
                                                    <tr>
                                                        <td>{item?.SubjectNamePashot}</td>
                                                        <td>{convertToPashto(item?.Oral2 + item?.Written2)}</td>
                                                        <td>{convertToPashto(item?.Oral1 + item?.Written1)}</td>
                                                        <td>{convertToPashto(item?.Oral1 + item?.Written1 + item?.Oral2 + item?.Written2)}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td>مجموعه</td>
                                                    <td>{convertToPashto(sumOral2 + sumWritten2)}</td>
                                                    <td>{convertToPashto(sumOral1 + sumWritten1)}</td>
                                                    <td>{convertToPashto(sumOral2 + sumWritten2 + sumOral1 + sumWritten1)}</td>
                                                </tr>
                                                <tr>
                                                    <td>دنمروسلنه</td>
                                                    <td>{percentage(rec)}</td>
                                                    <td>{percentage(rec)}</td>
                                                    <td>{percentage(rec)}</td>
                                                </tr>
                                                <tr>
                                                    <td>تقدیرات</td>
                                                    <td>{rec?.Status2}</td>
                                                    <td>{rec?.Status1}</td>
                                                    <td>{rec?.Status1}</td>
                                                </tr>
                                                <tr>
                                                    <td>په ټولګۍ کي درجه</td>
                                                    <td>{convertToPashto(rec?.Position2)}</td>
                                                    <td>{convertToPashto(rec.Position1)}</td>
                                                    <td>{convertToPashto(rec.Position1)}</td>
                                                </tr>
                                                <tr>
                                                    <td>نتیجه</td>
                                                    <td>{failPass(rec?.TotalGainedNumbers2)}</td>
                                                    <td>{failPass(rec?.TotalGainedNumbers1)}</td>
                                                    <td>{failPass(rec?.TotalGainedNumbers1)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className='secondColumn verticalAlignTop'>
                                        <img className="percentageSheet" src={percentageSheet} alt="logo" />
                                        <br/>
                                        <p className='wasilyTechnology'></p>
                                    </td>
                                    <td className='thirdColumn verticalAlignTop'>
                                        <table>
                                            <tr>
                                                <td className='verticalAlignTop'>
                                                    <table className='personalInfo'>
                                                        <tbody>
                                                            <tr>
                                                                <th colSpan={2}>د زده کوونکي پېژندنه</th>
                                                            </tr>
                                                            <tr>
                                                                <td>نوم</td>
                                                                <td>{record[0]?.Name_P}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>دپلارنوم</td>
                                                                <td>{record[0]?.FName_P}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>اساس نمبر</td>
                                                                <td>{convertToPashto(rec?.StudentSrn)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ټولګي</td>
                                                                <td>{record[0]?.ClassName_P}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <br />
                                                    <table className='colorTable attendance'>
                                                        <tbody>
                                                            <tr>
                                                                <td> دحاضرۍ
                                                                    <br /> ضوابط</td>
                                                                <td>سوب</td>
                                                                <td>ناسوب</td>
                                                                <td>مریض</td>
                                                                <td>رخصت</td>
                                                            </tr>
                                                            <tr>
                                                                <td>شپږمیاشتنۍ</td>
                                                                <td>{convertToPashto(rec?.Present2)}</td>
                                                                <td>{convertToPashto(rec?.Absent2)}</td>
                                                                <td>{convertToPashto(rec?.Sick2)}</td>
                                                                <td>{convertToPashto(rec?.Leaves2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>کلنۍ</td>
                                                                <td>{convertToPashto(rec?.Present1)}</td>
                                                                <td>{convertToPashto(rec?.Absent1)}</td>
                                                                <td>{convertToPashto(rec?.Sick1)}</td>
                                                                <td>{convertToPashto(rec?.Leaves1)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>مجموعه</td>
                                                                <td>{convertToPashto(rec?.Present1 + rec?.Present2)}</td>
                                                                <td>{convertToPashto(rec?.Absent1 + rec?.Absent2)}</td>
                                                                <td>{convertToPashto(rec?.Sick1 + rec?.Sick2)}</td>
                                                                <td>{convertToPashto(rec?.Leaves1 + rec?.Leaves2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <br />
                                                    <table className='attendance signature'>
                                                        <tbody>
                                                            <tr>
                                                                <td>لاسلیک ځای</td>
                                                                <td>شپږ<br /> میاشتنۍ</td>
                                                                <td>کلنۍ	</td>
                                                            </tr>
                                                            <tr>
                                                                <td>دټولګۍ نګران</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>ددارالعلوم مدیر</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>دزده کونکي ولي</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>ددارلعلوم مهر</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    }


                </div>

                        </td>
                        <td className='rightVertical'></td>
                    </tr>
                    <tr>
                        <td className='bottomLeft'>&nbsp;&nbsp;&nbsp;</td>
                        <td className='horizontal' ></td>
                        <td className='bottomRight'>&nbsp;&nbsp;&nbsp;</td>
                        </tr>
                </table>
                
            </div>
        </div>
    );
};

export default ReportCard;
