import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { CustomBtn } from '../../../../components/button/button';
import { PostRequest } from '../../../../redux/actions/PostRequest';
import { useDispatch, useSelector } from 'react-redux';

const Annoucement = (props) => {
    const dispatch = useDispatch();
    const url = useSelector((state) => state.Api);

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedTerm, setSelectedTerm] = useState('');

    // Generate the next 15 years
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear + i);

    const fee = () => {
        const confirmed = window.confirm("غواړئ فیس اعلان کړئ؟");

        if (confirmed) {
            dispatch(PostRequest(url.annnounceFees, JSON.parse(sessionStorage.getItem("User_Data")).token));
        } else {
            console.log("Fee announcement cancelled by user.");
        }
    };

    const salary = () => {
        const confirmed = window.confirm("غواړئ معاش اعلان کړئ؟");

        if (confirmed) {
            dispatch(PostRequest(url.annnounceSalary, JSON.parse(sessionStorage.getItem("User_Data")).token));
        } else {
            console.log("Salary announcement cancelled by user.");
        }
    };

    const exams = () => {
        console.log(`Exams announced for year ${selectedYear}, term ${selectedTerm}`);
    };

    return (
        <Grid container>
            <Grid item lg={12}>
                <h3>Please remember to keep announcing Fees and Salary regularly every month on selected date only once.</h3>
                <h2>مهرباني وکړئ په یاد ولرئ چې هره میاشت په ټاکل شوې نیټه یوازې یو ځل فیسونه او معاش په منظم ډول اعلان کړئ</h2>
                <br/>
            </Grid>
            <Grid item lg={4}>
                <CustomBtn data="Announcement Of Fee  د فیس اعلان" click={fee} />
            </Grid>
            <Grid item lg={2}></Grid>
            <Grid item lg={4}>
                <CustomBtn data="Announcement Of Salary  د معاش اعلان" click={salary} />
            </Grid>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Grid item lg={10}>
                <h2>
                    دامتحان کال وټاکئ 
                </h2>
            </Grid>
            <Grid item lg={10}>
                <FormControl fullWidth>
                    <InputLabel>Select Year</InputLabel>
                    <Select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        label="Select Year"
                    >
                        {years.map(year => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br/>
                <br/>
                <FormControl fullWidth>
                    <InputLabel>Select Term</InputLabel>
                    <Select
                        value={selectedTerm}
                        onChange={(e) => setSelectedTerm(e.target.value)}
                        label="Select Term"
                    >
                        <MenuItem value="Mid Term">Mid Term</MenuItem>
                        <MenuItem value="Final Term">Final Term</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                <br/>
                <CustomBtn data="Announcement Of Examination  د امتحان اعلان" click={exams} />
            </Grid>
        </Grid>
    );
};

Annoucement.propTypes = {};

export default Annoucement;
