import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CustomBtn } from '../../../components/button/button';

import ReactDOM from 'react-dom';
import ReportCard from './reportCard';

export default function FormTable(props) {
    // console.log(props.data)
    const [selectData, setSelectData] = useState([]);

    useEffect(() => {
        if (props.data && props.data) {
        const sortedData2 = props.data.sort((a, b) => a.Position - b.Position);
            setSelectData(sortedData2);
        }
    }, [props.data]);

    // console.log(selectData);

    const printReportCard = (getRecord) => {
        const newWindow = window.open('', '_blank', 'width=1000,height=800,left=100,top=100');
        newWindow.document.body.innerHTML = '<div id="root"></div>';
        const cssLink = newWindow.document.createElement('link');
        cssLink.rel = 'stylesheet';
        const url = window.location.href;
        const catchUrl = url.replace("/Private/StudentResult", "");
        cssLink.href = `${catchUrl}/reportCard.css`;
        newWindow.document.head.appendChild(cssLink);
    
        // Render React component with props
        ReactDOM.render(<ReportCard rec={getRecord} />, newWindow.document.getElementById('root'));
    };
    const getNameFName = (marksDetail) =>{
        const marksArray = Object.values(marksDetail);
        if (marksArray.length > 0) {
            const firstDetail = marksArray[0];
            return `${firstDetail.Name_P} / ${firstDetail.FName_P}`;
        }
    }
    
    return (
        <TableContainer component={Paper}>
            <div>{selectData[0]?.Date}</div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Roll Number</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Position</TableCell>
                        <TableCell>Grad</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectData.length > 0 ? (
                        selectData.map((row) => (
                            <TableRow key={row.StudentSrn}>
                                <TableCell>{row.StudentSrn}</TableCell>
                                <TableCell>{getNameFName(row.marksDetail)}</TableCell>
                                <TableCell>{selectData?.Term == 0 ? row.Position : row.Position2}</TableCell>
                                <TableCell>{selectData?.Term == 0 ?  row.Status : row.Status2}</TableCell>
                                <TableCell>
                                    <CustomBtn type="button" click={()=>printReportCard(row)} data="اطلاعنامه" />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={4}>No data available</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
