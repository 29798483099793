import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Main } from '../../../../constant'
import CustomForm from '../../../../components/form/form';
import dayjs from 'dayjs';
import { CustomBtn } from '../../../../components/button/button';
import AccessibleTable from '../../../../components/table/table';
import { Box, Grid, TableCell } from '@mui/material';
import PageContainer from '../../../../components/Container/pageContainer';
import CustomTable from '../../../../components/table/SimpleTable';
import { PostRequest } from '../../../../redux/actions/PostRequest';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { FullDate } from '../../../../components/Date/FullDate';
import TeacherReportComponent from '../../../../components/report/teacher';
import { ShowLoader } from '../../../../redux/actions/loader';
import Slider from '@mui/material/Slider';

const TeacherReport = (props) => {
  const [data, setData] = useState(Main().TeacherReport);
  const [select, setSelect] = useState('');
  const url = useSelector((state) => state.Api)
  const [getfilterdata, setGetfilterdata] = useState(data.inputs.filter((item) => item.feildtype !== 'label'));
  const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
  const [initialInputValues, setInitialInputValues] = useState(Object.fromEntries(
    getfilterdata
      .map((item) => [item.name, ''])
  ));
  const [formKey, setFormKey] = useState(initialInputValues);

  const [inputValues, setInputValues] = useState(initialInputValues);
  const [fessData, setFessData] = useState()
  const dispatch = useDispatch()
  const [rows, setRows] = useState()
  const [reportStatus , setReportStatus] = useState(4);

  async function handleInputChange(e) {
    setInputValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name == 'RollNum') {
      dispatch(ShowLoader('1'))
      const roll = { RollNum: e.target.value }
      await fetchstudent(roll)
      await fetchStudentReport(roll)
      setTimeout(async () => {
        await dispatch(ShowLoader('0'))
      }, 1000)
    }
  }
  const [studentOldDetails, setStudentOldDetails] = useState()
  const fetchstudent = (param) => {
    axios.post(url.showoldsalaryDetails, param, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setStudentOldDetails(res.data);
          // setMaxSelectableDate(dayjs(res.data.lastrecord))
        }
      })
      .catch((err) => {
        setStudentOldDetails("");
        // console.error(err); // Log the error response for debugging
      });
  }
  const fetchStudentReport = (param) => {
    axios.post(url.ShowReportTeacher, param, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.result);
          setFessData(res.data.result);
          // setMaxSelectableDate(dayjs(res.data.lastrecord))
        }
      })
      .catch((err) => {
        console.error(err); // Log the error response for debugging
      });
  }
  const ShowReportTeacher = (param) => {
    axios.post(url.showSpecificTeacher, param, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message);
          setRows(res.data.result);
          // setMaxSelectableDate(dayjs(res.data.lastrecord))
        }
      })
      .catch((err) => {
        console.error(err); // Log the error response for debugging
      });
  }
  const submitfunc = async (e) => {
    e.preventDefault();
    inputValues.reportStatus = reportStatus
    console.log(inputValues)
    await dispatch(PostRequest(url.AddReportTeacher, userToken, inputValues))
    fetchStudentReport(inputValues)
    refreshForm()
  }
  const ChangeDate = (e) => {
    // console.log(`${e.$y}-${e.$M}-${e.$D}`)
    console.log(`${e.$y}-${e.$M + 1}-${e.$D}`)
    setInputValues((prevValues) => ({
      ...prevValues,
      DateFrom: `${e.$y}-${e.$M + 1}-${e.$D}`,
    }));

  }
  const ChangeDateTo = (e) => {
    // console.log(`${e.$y}-${e.$M}-${e.$D}`)
    console.log(`${e.$y}-${e.$M + 1}-${e.$D}`)
    setInputValues((prevValues) => ({
      ...prevValues,
      DateTo: `${e.$y}-${e.$M + 1}-${e.$D}`,
    }));

  }

  const showattendence = (event) => {
    event.preventDefault();
    console.log(inputValues)
    ShowReportTeacher(inputValues)
  }
  // Function to refresh the form
  const refreshForm = () => {
    setFormKey(prevKey => prevKey + 1);
  }

  const marks = [
    {
      value: 1,
      label: 'ډیرښه',
    },
    {
      value: 2,
      label: 'ښه',
    },
    {
      value: 3,
      label: 'نارمل',
    },
    {
      value: 4,
      label: 'بد',
    },
    {
      value: 5,
      label: 'ډیربد',
    },
  ];
  function valuetext(value: number) {
    setReportStatus(value)
  }
  return (
    <PageContainer title='Student Report' description='Student Report'>
      <form onSubmit={submitfunc}>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <CustomForm key={formKey} handleInputChange={handleInputChange} data={data.inputs} />
              <br/>
                <div className='slider'>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={4}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks}
                    min={1}
                    max={5}
                  />
                </div>
                <br/>
              <CustomBtn data='Submit' style={{ maxWidth: '800px', marginTop: '15px' }} />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {
              studentOldDetails && (
                <TeacherReportComponent data={studentOldDetails.TeacherDetails} />

              )
            }
          </Grid>
        </Grid>
      </form>
      {
        <Box mt={5} sx={{ maxWidth: '1400px' }}>
          <Grid container>
            <Grid item lg={6}>
              {
                fessData && fessData.length != 0 && fessData != null && fessData != undefined ?
                  <>
                    <Box><b>Student Detailed Report  د متعلم تفصیلي راپور</b></Box>
                    <Box lg={6}>
                      <CustomTable
                        data={fessData}
                      />
                    </Box>

                  </>
                  : null

              }
            </Grid>
            <Grid item lg={6}>
              {
                studentOldDetails && studentOldDetails.result.length != 0 && studentOldDetails.result != null && studentOldDetails.result != undefined && (
                  <>
                    <Box><b>Student Detailed Fees Report  د متعلم تفصيلي فیس راپور</b></Box>
                    <Box >
                      <CustomTable data={studentOldDetails.result} />
                    </Box>
                  </>
                )
              }
            </Grid>
          </Grid>


          <br /><br /><br />

          {studentOldDetails && (
            <>
              <Box><b>Student Detailed Attendence Report  د متعلم تفصیلي حاضري راپور</b></Box>
              <form onSubmit={showattendence}>
                <Grid container>
                  <Grid item lg={6}>
                    <FullDate throwfullevent={ChangeDate} name='startDate' />
                  </Grid>
                  <Grid item lg={6}>
                    <FullDate throwfullevent={ChangeDateTo} name='endDate' />
                  </Grid>
                  <Grid item lg={12}>
                    <CustomBtn data="Show Attendention  حاضري وښيي" />
                  </Grid>
                </Grid>
                <br />
              </form>
            </>
          )}
          {
            rows && (
              <Box mt={2}>
                <AccessibleTable rows={rows} />
              </Box>
            )
          }
        </Box>
      }
    </PageContainer>
  )
}

TeacherReport.propTypes = {}

export default TeacherReport