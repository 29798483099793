import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import PageContainer from '../../../../components/Container/pageContainer';
import { Input } from '../../../../components/input/input';
import CustomForm from '../../../../components/form/form';
import { Main } from '../../../../constant';
import { useTheme } from '@emotion/react';
import AccessibleTable from '../../../../components/table/table';
import { CustomBtn } from '../../../../components/button/button';
import { CustomSelect } from '../../../../components/select/select';
import SuggestionInput from '../../../../components/suggestionInput/suggestionInput';
import ShowClass from './components/showClass';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { PostRequest } from '../../../../redux/actions/PostRequest';
import { ShowLoader } from '../../../../redux/actions/loader';

const StudentAttendance = (props) => {
    const [data , setData ] = useState(Main().TeacherAttendance);
    const [suggestions , setSuggestions ] = useState();
    const dispatch = useDispatch();
    const [select , setSelect] = useState('');
    const [tableData , setTableData ] = useState();
    const [getfilterdata , setGetfilterdata ] = useState(data.inputs.filter((item) => item.feildtype !== 'label'));
    const [initialInputValues , setInitialInputValues] = useState(Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    ));
    const [ FilterData , setFilterData ] = useState();

    const theme = useTheme();
    const style = theme.palette.Main.Attendance;

    const [inputValues, setInputValues] = useState(initialInputValues);
    const [ focusData , setFocusData ] = useState(null);
    
    const url = useSelector((state) => state.Api.studentsuggestion);
    const Studentshowattendence = useSelector((state) => state.Api.Studentshowattendence);
    const manaulurl = useSelector((state) => state.Api.Studentmaunaulattendence);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const [update , setUpdate] = useState(0);
    const [currentTab, setCurrentTab] = useState(0); // State variable to control the current tab

    useEffect(() => {
        console.log('--------------------------------')
        console.log(suggestions != undefined)
        // console.log(suggestions.length)
        try {
            if(suggestions == undefined || suggestions == null){
                axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        console.log(res.data.message);
                        setSuggestions(res.data.message);
                    }
                })
                .catch((err) => {
                    console.error(err); // Log the error response for debugging
                });
            }
        } catch (err) {
            console.error(err);
        }
    }, [update]);

    function handleInputChange(e) {
        // console.log(e)
        if(e[0]){  
            const val = e[0].substring(1, e[0].length - 1);
            const string = 'R'+val;
            setInputValues((prevValues) => ({
                ...prevValues,
                [string]: e[1],
            }));
        }
        else{
            setInputValues((prevValues) => ({
                ...prevValues,
                [e.target.name]: e.target.value,
            }));
        }
    }
    
    function handleRadioChange(e) {
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.placeholder,
        }));
    }

    //Get Current Time of Attendance
    const currentTime = new Date();
    let hours = currentTime.getHours();
    const minutes = String(currentTime.getMinutes()).padStart(2, '0');
    const seconds = String(currentTime.getSeconds()).padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const getCurrentTime = `${hours}:${minutes}:${seconds} ${amPm}`;

    console.log(getCurrentTime)

    //table data

    //form 
    const submitForm = async(e) => {
        e.preventDefault();

        // alert(inputValues.startDate == '' ? new Date() : inputValues.startDate)
        const startDate = new Date(inputValues.startDate == '' ? new Date() : inputValues.startDate);
        const endDate = new Date(inputValues.endDate == '' ? new Date() : inputValues.endDate);
  
        // Function to format date as 'YYYY-MM-DD'
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
  
        // Generate an array of objects representing the date range
        const dateRangeArray = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dateRangeArray.push({ date: formatDate(currentDate) });
            currentDate.setDate(currentDate.getDate() + 1);
        }
  
        // console.log(dateRangeArray);
        inputValues.getDate = dateRangeArray;
        inputValues.getCurrentTime = getCurrentTime;
        console.log(inputValues)

        await dispatch(PostRequest(manaulurl,userToken,inputValues))
        // setUpdate(1)
        updatefunc('old')
    }

    const updatefunc = (getparam) => {
        if(getparam == 'old'){
            getparam = update
        }
        setUpdate(getparam)
        console.log(getparam)
        setTableData('');
        try {
            dispatch(ShowLoader('1'))
            axios.get(`${Studentshowattendence}${getparam}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(ShowLoader('0'))
                    console.log(res.data.result);
                    setTableData(res.data.result);
                }
            })
            .catch((err) => {
                console.error(err); // Log the error response for debugging
            });
        } catch (err) {
            console.error(err);
        }
    }

    const ChangeDate = (e , name) =>{
        console.log(e , name)
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: e
        }));
    }

    const updateAttendance = async (getparam, attStatus, rollNum) => {
        console.log(attStatus)
        if(getparam == 'old'){
            getparam = update
        }
        console.log(getparam)
        setTableData(''); 
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const catchDate =  [];
        catchDate.push({ date: `${year}-${month}-${day}` });
          
        const getDetails = {
            Comments: "",
            RollNum:rollNum,
            Status:attStatus,
            getCurrentTime: getCurrentTime,
            getDate: catchDate
        }
        const getStat = await dispatch(PostRequest(manaulurl, userToken, getDetails));
        console.log(getStat)
        setTableData('');
        updatefunc('old')
    }

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };
 
    return (
        <PageContainer title="Student Attendance">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label=" Daily Attendance ورځنۍ حاضري " />
                    <Tab label="Leaves  رخصتی" />
                    {/* <Tab label="Tab 3" /> */}
                </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
                {/* Content for Tab 1 */}
                <Box>
                    <ShowClass func={updatefunc} highlightClass={update} />
                </Box>
                
                <Box mt={5}>
                    <Grid container>
                        <Grid item lg={10}>
                            {
                                tableData && tableData.length > 0 ? (
                                    <AccessibleTable callAttendanceFun={updateAttendance} attendancePage='yes'  rows={tableData} />
                                )
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
            <Box>
                    <form onSubmit={submitForm}>
                        <Grid container>
                            <Grid item lg={6}>
                                <Box>
                                    {
                                        suggestions && (
                                            <SuggestionInput 
                                                simple='true'
                                                handleInputChange={handleInputChange}
                                                Suggestions={suggestions}
                                                style={style.children}
                                                name={data.text.name}
                                                placeholder={data.text.placeholder}
                                            />
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={12}>
                                <CustomForm data={data.inputs}  handleRadioChange={handleRadioChange}  ChangeDate={ChangeDate}  handleInputChange={handleInputChange}  />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={6}>
                                <div style={{marginTop:'20px'}}></div>
                                <CustomBtn data='Submit' />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </TabPanel>
            {/* <TabPanel value={currentTab} index={2}>
            </TabPanel> */}
        </PageContainer>
    )
}

StudentAttendance.propTypes = {}

export default StudentAttendance;

// Function to handle the TabPanel component
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}
