import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { Input } from '../../../../components/input/input';
import { CustomBtn } from '../../../../components/button/button';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Column from './column';


export default function FormTable(props) {
  console.log(props.data[0])
  const [data , setData ] = useState(props?.data)
  const theme = useTheme();
  const style = theme.palette;
  const tableStyle = theme.palette.Main.tableStyle;

  // console.log(props?.data)
  return (
    <Box >
      <Box>
        Subject:  {props?.data[0]['Subject Name Pashto']} {props?.data[0]['Subject Name']}<br/>
        Passing:  {props?.data[0]['Passing Marks']} <br/>
        Total Marks:  {props?.data[0]['Total']} <br/>
        Examination: {props?.data[0].scheduleTerm == 0 ? 'Mid Term' : 'Final Term'} 
      </Box>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow  sx={{background: style.primary.light}} id="thead">
            {/* {props?.data &&
              Object.keys(props?.data[0]).map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))} */}
                <TableCell>Roll Num</TableCell>
                <TableCell>Name</TableCell>
                {/* <TableCell>Phasto Name</TableCell> */}
                {/* <TableCell>Subject Name</TableCell> */}
                {/* <TableCell>Total</TableCell> */}
                <TableCell>Written</TableCell> 
                <TableCell>Oral</TableCell> 
                {/* <TableCell>HW</TableCell>  */}
                {/* <TableCell>Activity</TableCell>  */}
                {/* <TableCell>Total Marks</TableCell>  */}
                <TableCell>Action</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data &&
            props?.data.map((row, ind) => (
                <TableRow key={ind}>
                   <Column row={row} />
                </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
}
