import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Main } from '../../../../constant'
import CustomForm from '../../../../components/form/form';
import { Box, Grid } from '@mui/material';
import { CustomBtn } from '../../../../components/button/button';
import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import PageContainer from '../../../../components/Container/pageContainer';
import Selectclass from '../Components/selectclass';
import CreateClass from '../Components/Createclass';
import AddSubjectform from '../Components/addsubjectform';
import { editSbuject } from '../../../../redux/actions/subject/editsubject';
import { deleteSubject } from '../../../../redux/actions/subject/deletesubject';
import { useDispatch, useSelector } from 'react-redux';
import { PostRequest } from '../../../../redux/actions/PostRequest';


const Maincomponent = props => {
    const [number , setNumber ] = useState(0);
    const [inputValues, setInputValues] = useState({    });
    const [currentClass , setCurrentClass] = useState('')
    const [data , setData] = useState(Main().subject);
    const butnstyle = {borderRadius:'50%',width:'10px', padding: '9px', minWidth: '48px', marginTop: '8px'};
    const url = useSelector((state)=>state.Api)
    const dispatch = useDispatch()

    const edit = (values) => {
        console.log('Clicked on edit');
        const userToken = JSON.parse(sessionStorage.getItem('User_Data')).token;
        dispatch(editSbuject(url.UpdateSubject, userToken, values.data));
      };
    
      const deletesub = (values) => {
        console.log(values)
        console.log('Clicked on delete');
        const userToken = JSON.parse(sessionStorage.getItem('User_Data')).token;
        dispatch(deleteSubject(url.DeleteSubject, userToken, values));
      };
    
      const AddNewSubject = (values) => {
        console.log('Clicked on add new subject');
        console.log(values);
        const userToken = JSON.parse(sessionStorage.getItem('User_Data')).token;
        dispatch(PostRequest(url.AddSubject, userToken, values));
      };
    
      const AddSubject = () => {
        setNumber((prevNumber) => prevNumber + 1);
        const customFormElement = React.createElement(AddSubjectform, {
          edit,
          deletesub,
          AddNewSubject,
          currentClass: currentClass
        });
    
        // Create a container for the portal
        const portalContainer = document.createElement('div');
        portalContainer.setAttribute('class', 'MuiGrid-root MuiGrid-container css-11lq3yg-MuiGrid-root');
        portalContainer.setAttribute('id', `container-${number}`);
        portalContainer.setAttribute('style', `margin-top: 3px;`);
    
        // Create a new div element
        let newDiv = document.createElement('div');
        newDiv.setAttribute('id', `div-${number}`); 
    
        
        let crossSpan = document.createElement('span');
        crossSpan.setAttribute('class', 'crossicon');
        crossSpan.textContent = '\u00D7'; 
        crossSpan.addEventListener('click', () => removeDiv(number));
        newDiv.appendChild(crossSpan);
        ReactDOM.render(customFormElement, portalContainer);
        portalContainer.appendChild(newDiv);
        document.getElementById('items').appendChild(portalContainer);
    };



    function removeDiv(getparam) {
        console.log(`container-${getparam}`);
        document.getElementById(`container-${getparam}`).remove();
    }
    
      

    function handleInputChange(e) {
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));
    }

    const submitForm = (e) =>{
        e.preventDefault();
        console.log(inputValues)
    }
    const CurrentClass =(getparam) =>{
        console.log(getparam)
        setCurrentClass(getparam)
    }
  return (
    <PageContainer title={data.tile} description={data.description}>
        <div>
            <CreateClass />
                <Selectclass  Current={CurrentClass} />
            <form onSubmit={submitForm}>
                <Grid container>
                    {/* <CustomForm data={data.repeatedinputs} handleInputChange={handleInputChange} /> */}
                    {
                        currentClass != "" ?
                    <Grid item lg={2} mt={2}>
                        <CustomBtn data='Add Subject' type='button' title='Add More Subjects' click={AddSubject} />
                    </Grid>
                    :
                    null
                    }
                </Grid>
                <Grid container id="items" sx={{maxWidth:'100%'}}>
                
                </Grid>
            </form>
        </div>

    </PageContainer>
  )
}

Maincomponent.propTypes = {}

export default Maincomponent