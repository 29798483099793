import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, Grid } from '@mui/material';
import { CustomSelect } from '../../../../../components/select/select';
import Updatestatus from './updatestatus';
import { CustomBtn } from '../../../../../components/button/button';
import { CustomUL } from '../../../../../components/select/customUL';

const ShowClass = (props) => {
  const [data, setData] = useState([]);
  const [updatevalue, setUpdatevalue] = useState()
  const url = useSelector((state) => state.Api.Studentcurrentdayreport);
  const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;

  useEffect(() => {
    try {
      axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.result);
            setData(res.data.result);
          }
        })
        .catch((err) => {
          console.error(err); // Log the error response for debugging
        });
    } catch (err) {
      console.error(err);
    }
  }, [updatevalue]);

  const update = async (event) => {
    await props.func(event)
    await setUpdatevalue(event)
  }
  const myfunc = async (param) => {
    props.func(param)
  }
  return (
    <>
      <Grid container mb={3}>
        {data.map((item, index) => (
          <Grid item key={index} lg={2} >
            <Box>
              {/* {item.ClassName} */}
              {
                // item.Status == 'empty' ?                   
                  
                  <Box >
                    
                    <nav className='navigationClass'>
                    <ul>
                      <li className={item.Srn == props.highlightClass ? 'selectedClass' : 'noDropDown'} id={item.Srn} onClick={() => myfunc(item.Srn)}>
                          <span>{item.ClassName}</span> <br/>
                          {/* <span>{item.Status}</span> */}
                      </li>
                    </ul>
                  </nav>
                    {/* <CustomBtn
                      click={() => myfunc(item.Srn)}
                      data="Show Table"
                    /> */}
                  </Box>
            //       :
            // <Updatestatus func={update} data={item.Srn} classes={item.ClassName} />

              }
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ShowClass.propTypes = {};

export default ShowClass;
