import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { CustomBtn } from '../../../../components/button/button'

function PrintStudentsCards(props) {
    console.log(props.info)
    const image = useSelector((state)=>state.Api.imageServer);
    const WebSrn = localStorage.getItem('WebSrn');
    const [qrCodeData, setQRCodeData] = useState('');
    const [catchUrl, setCatchUrl] = useState('');
    const [cards, setCards] = useState();

    const generateQRCode = () => {
        console.log(props.info.RollNum)

        const dataObj = {
            roleNo: String(props.info.RollNum),
            name: props.info.Name_P,
        };
        const getQrCodeData = `smartschool,${props.info.RollNum},${props.info.Name}`;
        setQRCodeData(getQrCodeData);
    };

    function separateLanguages(text) {
        const arabicRange = /[\u0600-\u06FF]/;
        const englishChars = [];
        const arabicChars = [];
    
        for (let char of text) {
            if (arabicRange.test(char)) {
                arabicChars.push(char);
            } else {
                if (char !== ' ') { // Check if the character is not a space
                    englishChars.push(char);
                } else {
                    arabicChars.push(char); // Preserve space in Arabic text
                }
            }
        }
    
        const englishText = englishChars.join('');
        const arabicText = arabicChars.join('');
    
        return arabicText;
    }
    
    useEffect(() => {
        setCards(props.info)
        console.log(cards)
        generateQRCode();
        let currentUrl = window.location.href;
        const containsStudents = currentUrl.includes('Students');
        const classStudents = currentUrl.includes('Subjects');

        if (containsStudents) {
            currentUrl = currentUrl.replace("/Private/View/Students", "");
        }
        else if (classStudents) {
            currentUrl = currentUrl.replace("/Private/Subjects", "");
        } else {
            currentUrl = currentUrl.replace("/Private/View/Teacher", "");
        }
        setCatchUrl(currentUrl);
    }, []);

    const NewWindowContent = () => {
        return (
            <div className='multipleCards'>
                {cards.map((card, ind) => (
                     <div id="specificDiv" className="specificDivClass">
                     <div className="card">
                     <div className="card-header">
                         <div className='image'>       
                             <img className="student-photo" src={`${image}${WebSrn}/Logo.png`} alt="Student Photo" />
                         </div>
                         <p>واصلي ټیکنالوژي</p>
                     </div>
                     <div className={card?.studentOrTeacher == 'student' ? 'teacherLine student' : 'teacherLine teacher'} ></div>
                     <div className='qrCode' style={ card?.studentOrTeacher != 'student' ? {padding: '10px 62px'} : null}>
                         {/* {qrCodeData && ( */}
                             <div>
                                 <QRCode value={`smartschool,${card?.RollNum},${card?.Name}`} size={card?.studentOrTeacher == 'student'? 70 : 80} />
                             </div>
                         {/* )} */}
                     </div>
                    <div className="card-body" style={ card?.studentOrTeacher != 'student' ? {padding: '7px 6px'} : null}>
                         <div className='rollNumber'>{card?.studentOrTeacher != 'student'? null : card?.RollNum}</div>
                         <div className={card?.studentOrTeacher == 'student' ? 'student-info' : 'teacher-info'} >
                             <h2>{` ${card?.Name_P}`}</h2>
                             <h2>{`${card?.Name}`}</h2>
                             <p>{card?.studentOrTeacher == 'student'? card?.FName_P : null}</p>
                             <h2>{separateLanguages(card?.Class)}</h2>
                         </div> 
                         <div><table className='tradeMark'><tr><td>انجينر0703131865 </td></tr></table></div>
                     </div>
                     <div className={card?.studentOrTeacher == 'student' ? 'card-footer student' : 'card-footer teacher'} ></div>
                     </div>
                 </div>
                ))}
            </div>
        );
    };

    const openNewWindow = () => {
        setCards(props.info)
        const windSize = props.getSize === 'full' ? 'width=816,height=1056' : 'width=224,height=365';
        const newWindow = window.open('', '_blank', windSize);
        newWindow.document.body.innerHTML = '<div id="root"></div>';
        
        const cssLink = newWindow.document.createElement('link');
        cssLink.rel = 'stylesheet';
        cssLink.href = `${catchUrl}/XaoSoft.css`;
        newWindow.document.head.appendChild(cssLink);

        // Render the NewWindowContent using ReactDOM.render
        ReactDOM.render(<NewWindowContent />, newWindow.document.getElementById('root'));
    };

    return (
        <>
            <CustomBtn type="button" click={openNewWindow} data="Print Card  کارت چاپ کړئ" />
        </>
    );
}

export default PrintStudentsCards;
