import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomForm from '../../../../components/form/form';
import {CustomBtn} from '../../../../components/button/button';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { Main } from '../../../../constant';
import SuggestionInput from '../../../../components/suggestionInput/suggestionInput'
import { MobileAppApi } from '../../../../redux/actions/mobileApp/mobileApp';
import { ShowLoader } from '../../../../redux/actions/loader'
import axios from 'axios'
import AccessibleTableMobileApp from '../../../../components/table/mobileAppTable'

const RegParent = (props) => {
    const dispatch = useDispatch();
    const url = useSelector((state) => state.Api);

    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const [data, setData] = useState(Main().MobileApp);
    const getfilterdata = data.inputs.filter((item) => item.feildtype !== 'label');
    // const [loadDate, setLoadDate] = useState(DateGlobalConvertor(dayjs(Date.now())))
    const initialInputValues = Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    );

    const [inputValues, setInputValues] = useState(initialInputValues);
    const [suggestions , setSuggestions] = useState();
    const [btnDisable , setBtnDisable] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([])
    const [formKey, setFormKey] = useState(0);
    const api = useSelector((state) => state.Api)
    const [fetchMobileData, setFetchMobileData] = useState();

    const submitForm = (event) => {
        event.preventDefault();
        console.log(selectedStudents.length <= 0)
       
        setBtnDisable(true)
        dispatch(ShowLoader('1'))
        dispatch(MobileAppApi(api.MobileApp, JSON.parse(sessionStorage.getItem("User_Data")).token, JSON.stringify(inputValues) ))
        setTimeout(() => {
            refreshForm();
            setInputValues(initialInputValues)
            setSelectedStudents([])
            setBtnDisable(false)
            fetchMobileRegisteredData()
        }, 1000)
    }
    function handleInputChange(e){
        console.log(selectedStudents)
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
            Students: selectedStudents,
        }));
        
    }

 
    function suggestionhandleInputChange(e) {
        const newValue = e.target.value;
        if (selectedStudents.includes(newValue)) {
            //alert('Value already exists!');
        } else {
            setSelectedStudents(prevSelectedStudents => [...prevSelectedStudents, newValue]);
        }
        
    }

    useEffect(() => {
        // Log the updated selectedStudents after the state update
        setInputValues((prevValues) => ({
            ...prevValues,
            Students: selectedStudents,
        }))
    }, [selectedStudents]);
    
    function generateOTP(){
        alert();
    }
    function removeStudent(item, ind){
        if (selectedStudents.includes(item)) {
            const updatedStudents = selectedStudents.filter((student, index) => index !== ind);
            setSelectedStudents(updatedStudents);        }
    }

    function fetchSuggestions(){
        try {
            dispatch(ShowLoader('1'))
            axios.get(url.studentsuggestion, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data.message);
                setSuggestions(res.data.message);
                dispatch(ShowLoader('0'))
              }
            })
            .catch((err) => {
              console.error(err); // Log the error response for debugging
            });
          } catch (err) {
            console.error(err);
          }
    }

    function sortDataDescendingBySrn(data) {
        console.log(data)
        return data.sort((a, b) => b.Srn - a.Srn);
    }
    
    function fetchMobileRegisteredData(){
        try {
            setFetchMobileData()
            dispatch(ShowLoader('1'))
            axios.get(url.MobileAppList, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data.message);
                setFetchMobileData(sortDataDescendingBySrn(res.data.message));
                dispatch(ShowLoader('0'))
              }
            })
            .catch((err) => {
                dispatch(ShowLoader('0'))
              console.error(err); // Log the error response for debugging
            });
          } catch (err) {
            console.error(err);
          }
    }
    useEffect(() => {
        // fetchSuggestions();
        fetchMobileRegisteredData()
      }, []);

      // Function to refresh the form
    const refreshForm = () => {
        setFormKey(prevKey => prevKey + 1);
    }
    return(
        <>
        <form onSubmit={submitForm} className='mobileAppComponent'>
            <Grid container >
                <CustomForm key={formKey} data={data.inputs} handleInputChange={handleInputChange} generateOTP={generateOTP} />
            </Grid>
            <Grid container mt={1}>
                {/* <Grid lg={3} item mt={1} className='selectstd'><label>Select Student  متعلم وټاکئ</label></Grid>
                <Grid lg={9} item>
                    <Box>
                    <SuggestionInput 
                        handleInputChange={suggestionhandleInputChange}
                        Suggestions={suggestions}
                        placeholder='Roll No / Name'
                        name='RollNum'
                        />
                    </Box>
                </Grid>

                { selectedStudents.length > 0 ?
                    <>
                    <Grid lg={3} item mt={3} className='selectstd'><label>Selected Student  ټاکل شوی متعلم </label></Grid>
                        <Grid lg={6} item className='selectedStudents'>
                        {selectedStudents.map((value, index) => (
                            <div onClick={(event)=>removeStudent(value, index)} key={value}>Student Roll - {value}</div>
                        ))}
                        </Grid>
                </>
                : 
                null

                }
                  */}
            </Grid>
            <Grid container>
                             
            <Grid lg={3} item></Grid>
                <Grid lg={6} item>
                    <Box>
                        <CustomBtn disable={btnDisable} data={data.butn} style={{ margin: 'auto', display: 'block', marginTop: '10px' }} />
                    </Box>
                </Grid>
            </Grid>
        </form>

        <Box mt={5}>
        <Grid container>
            <Grid item lg={10}>
                {
                    fetchMobileData && fetchMobileData.length > 0 ? (
                        <AccessibleTableMobileApp  rows={fetchMobileData} />
                    )
                    :
                    null
                }
            </Grid>
        </Grid>
        </Box>
        </>
    )
}

export default RegParent