import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MainClass } from '../../../../components/select/classs'
import Selectclass from './selectclass'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import CustomTable from '../../../../components/table/SimpleTable'
import { ShowLoader } from '../../../../redux/actions/loader'
import { Box, Grid } from '@mui/material';
import PrintStudentCards from './printStudentsCards'
import SuggestionInput from '../../../../components/suggestionInput/suggestionInput';
import { CustomBtn } from '../../../../components/button/button'

const PrintStudentCardsMultiple = (props) => {
  const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
  const [currentClass , setCurrentClass] = useState('')
    const [classes , setClasses] = React.useState(); // Fetch classes outside the component
    const url = useSelector((state) => state.Api);
    const dispatch = useDispatch();
    const [suggestions , setSuggestions] = useState();
    const [teacherSuggestion , setTeacherSuggestion] = useState();
    


    const CurrentClass =(getparam) =>{
       console.log(getparam)
       setCurrentClass(getparam)
   }
//    const func = () => {
    
//    }
   const ChangeOnSelect = (getparam) => {
    dispatch(ShowLoader('1'))
    console.log(getparam[1],getparam[0])
        // axios.get()
        const myfunc = async()=>{
            const userToken =
            JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
            const res = await axios.get(`${url.showClassStudents}${getparam[0]}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
              }
            });
            if (res.status === 200) {
              const modifiedData = res.data.result.map(student => ({
                ...student,
                RollNum: student['Roll Number']
                // Optionally remove the original property
                // 'Roll Number': undefined
              }));
            setClasses(modifiedData);
              // setClasses(res.data.result)
                dispatch(ShowLoader('0'))
            }
          }
          myfunc()
    }

    function removeStudent(item, ind){
      if (classes.includes(item)) {
          const updatedStudents = classes.filter((student, index) => index !== ind);
          setClasses(updatedStudents);        }
  }

  function suggestionhandleInputChange(e) {
    const newValue = e.target.value;
    const record = suggestions.find(item => item.roll === newValue);

    setClasses(prevSelectedStudents => [...prevSelectedStudents, record]);
    // if (classes.includes(newValue)) {
    //     //alert('Value already exists!');
    // } else {
    //     setClasses(prevSelectedStudents => [...prevSelectedStudents, newValue]);
    // }    
  }
  
  function teacherSuggestionInputHandle(e) {
    const newValue = e.target.value;
    const record = teacherSuggestion.find(item => item.roll === newValue);

    setClasses(prevSelectedStudents => [...prevSelectedStudents, record]);
    // if (classes.includes(newValue)) {
    //     //alert('Value already exists!');
    // } else {
    //     setClasses(prevSelectedStudents => [...prevSelectedStudents, newValue]);
    // }    
  }

  function callStudents(){
    try {
      dispatch(ShowLoader('1'))
      axios.get(url.studentCards, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message);
          setSuggestions(res.data.message);
          dispatch(ShowLoader('0'))
        }
      })
      .catch((err) => {
        console.error(err); // Log the error response for debugging
      });
    } catch (err) {
      console.error(err);
    }
  }

  function callTeachers(){
    try {
      dispatch(ShowLoader('1'))
      axios.get(url.teacherCards, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message);
          setTeacherSuggestion(res.data.message);
          dispatch(ShowLoader('0'))
        }
      })
      .catch((err) => {
        console.error(err); // Log the error response for debugging
      });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
        <MainClass GetSelectedValue={(e)=>ChangeOnSelect(e)} />
        <br />
        
        {
            classes && classes.length != 0 && classes != null && classes != undefined  ? (
                <>
                <Grid item lg={12} md={12}>
                    <SuggestionInput 
                        handleInputChange={suggestionhandleInputChange}
                        Suggestions={suggestions}
                        placeholder='Student Roll No / Name'
                        name='RollNum'
                        />
                </Grid>
                <Grid item lg={5} md={5}>
                  <CustomBtn type="button" click={()=>callStudents()} data="Load Students" />
                </Grid>
                <br/>
                <Grid item lg={12} md={12}>
                    <SuggestionInput 
                        handleInputChange={teacherSuggestionInputHandle}
                        Suggestions={teacherSuggestion}
                        placeholder='Teacher Roll No / Name'
                        name='RollNum'
                        />
                </Grid>
                <Grid item lg={5} md={5}>
                  <CustomBtn type="button" click={()=>callTeachers()} data="Load Teachers" />
                </Grid>
                <br/>
                  { classes.length > 0 ?
                            <Grid lg={12} item className='classes'>
                            {classes.map((value, index) => (
                                <div className='printCardStd' onClick={(event)=>removeStudent(value, index)} key={index}> {value.Name}  {value['RollNum']} </div>
                            ))}
                            </Grid>
                    : 
                    null
                          }
                            <br/>
        <Grid lg={12} item mt={2}>
          <p>{classes.length} متعلم تعداد</p>
        </Grid>

        <br/>
        <PrintStudentCards info={classes} getSize = 'full'/>
                </>
            )
            :
            null
        }
        

    </>
  )
}

PrintStudentCardsMultiple.propTypes = {}

export default PrintStudentCardsMultiple