import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PageContainer from '../../../components/Container/pageContainer'
import { Main } from '../../../constant'
import { Box, Grid } from '@mui/material'
import { CustomSelect } from '../../../components/select/select'
import { Date } from '../../../components/Date/Date'
import { Input } from '../../../components/input/input'
import { CustomBtn } from '../../../components/button/button'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { SelectOnObject } from '../../../components/select/selectOnObject'
import FormTable from './components/table'
import { ShowLoader } from '../../../redux/actions/loader'

const Shoqa = (props) => {
    const [ data , setData ] = useState(Main().Shoqa);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const url = useSelector((state) => state.Api);
    const [ update , setUpdate ] = useState()
    const [termYear, setTermYear] = useState({ getTerms: '' });
    const [term, setTerm] = useState({ terms: '' });
    const [ serverData , setServerData ] = useState({
        ShowClass:'',

    })
    const dispatch = useDispatch()


    const [ inputValues , setInputValues ] = useState({
        class:'',
        subject:'',
        teacher:''
    })
    useEffect(() => {
        try {
          dispatch(ShowLoader('1'))
          axios.get(url.ShowClass, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
            dispatch(ShowLoader('0'))

              //fetchResultYear()
              //console.log(res.data.message);
            //   setServerData({ShowClass:res.data.result});
            setServerData(
                (oldData)=> ({
                    ShowClass:res.data.result,
                    showSubject:[]
                  }
              ));
            }
          })
          .catch((err) => {
            //console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          //console.error(err);
        }
 

        
      }, [update]);

      
      const showSubject = (param) => {
        console.log(serverData)
            try {
              dispatch(ShowLoader('1'))
              axios.get(`${url.ShowSubject}/${param}`, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${userToken}`,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  dispatch(ShowLoader('0'))
                  console.log(res.data.message);
                  setTermYear({ getTerms: '' })
                  // fetchResultYear()
                  setServerData(
                    (oldData)=> ({
                        ...oldData,
                        showSubject:res.data.message,
                        Result:''}
                  ));
                }
              })
              .catch((err) => {
                //console.error(err); // Log the error response for debugging
              });
            } catch (err) {
              //console.error(err);
            }
      }
      const ShowResult = async(param) => {
        console.log(param)
        try {
          dispatch(ShowLoader('1'))
          // const payload = {SubjectSrn:inputValues.SubjectSrn != ''? inputValues.SubjectSrn : param, Term: inputValues.Term != '' ? inputValues.Term : param}
          const payload = {
            SubjectSrn:param[0] != 'SubjectSrn'? inputValues.SubjectSrn : param[1],
            parentShoqa: param
          }
          //console.log(payload)
          await axios.post(url.showShoqa,payload, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            dispatch(ShowLoader('0'))
            if (res.status === 200) {
              //console.log(res.data.message);
              const getResult = res.data.result.map(item => ({
                ...item,      // Spread the existing properties
                parentShoqaSrn: term.Srn,  // Add the new property
                scheduleTerm: term.TermSrn
              }));
              console.log(getResult)
              setServerData(
                (oldData)=> ({
                    ...oldData,
                    Result:getResult}
              ));
            }
          })
          .catch((err) => {
            //console.error(err); // Log the error response for debugging
          });
        } catch (err) {
          //console.error(err);
        }
  }
    const ChangeOnSelect = async(getparam) => {
      console.log(termYear.getTerms)
      console.log(serverData.ShowClass)
      
      // console.log(getparam[1],getparam[0])
      const data = getparam[0] == 'Mid' ? 0 : 1
      if(getparam[1] == 'Term'){
        setInputValues((oldData)=> ({
          ...oldData,
          [getparam[1]]:data
      })
      )
      }
      else{
        setInputValues((oldData)=> ({
            ...oldData,
            [getparam[1]]:getparam[0]
        })
        )

      }
      setTerm({ terms: '' })
      setTermYear({ getTerms: '' })
      fetchResultYear()
      setServerData(
        (oldData)=> ({
            ...oldData,
            Result:''}
      ));
      // console.log(serverData)
        if(getparam[1] == 'class'){
            await showSubject(getparam[0])
        }
        // if(getparam[1] == 'SubjectSrn'){
        //   //console.log(getparam)
        //   await ShowResult(['SubjectSrn',getparam[0]])
        // }
        if(getparam[1] == 'Term' && serverData.showSubject.length >0){
          //await ShowResult(data)
        }
    }

    useEffect( () => {


    },[serverData])

    const fetchResultYear = async () => {
      try {
          dispatch(ShowLoader('1'))
          const yearResponse = await axios.get(url.resultYear, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${userToken}`,
              },
          });

          if (yearResponse.status === 200) { 
              dispatch(ShowLoader('0'))

              setTermYear(prevData => ({
                  ...prevData,
                  getTerms: yearResponse.data.result
              }));
          }
      } catch (err) {
          console.error('Error fetching result year:', err);
      }
      console.log(termYear)
  };


const ChangeTermsSelect = (event) => {
  const termSrn = event[0]
  const arr = JSON.stringify(termYear)
  const getArr = JSON.parse(arr)
  const getTermSrn = getArr.getTerms.find((item) => { return item.Srn == termSrn})
// console.log(serverData.Result)
  setTerm(getTermSrn) 
  setServerData(
    (oldData)=> ({
        ...oldData,
        Result:''}
  ));
}

const openShoqa = () =>{
  // ShowResult(term.Srn)
  if(serverData.showSubject.length <= 0 || term.terms === ''){
    alert('please Select Class, Subject and Term')
  }
  else{ShowResult(term.Srn)}

}
  return (
    <PageContainer title={data.title} description={data.description}>
        <Box className='printShoqa'>
            <Grid container>
                {
                    serverData.ShowClass && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option='ClassName' items={serverData.ShowClass} GetSelectedValue={(e)=>ChangeOnSelect(e)} name='class' />
                            </Box>
                        </Grid>
                    )
                }
                {
                    serverData.ShowClass && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option="SubjectName" items={serverData.showSubject} GetSelectedValue={(e)=>ChangeOnSelect(e)} name='SubjectSrn' />
                            </Box>
                        </Grid>
                    )

                }
                {/* {
                    serverData.teachersuggestion && (
                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option="name" items={serverData.teachersuggestion}  GetSelectedValue={(e)=> (e)} name='teacher' />
                            </Box>
                        </Grid>
                    )
                } */}
                    {
                      termYear.getTerms && (

                        <Grid item lg={6}>
                            <Box p={1}>
                                <SelectOnObject option="TermYear" items={termYear?.getTerms}  GetSelectedValue={(e)=>ChangeTermsSelect(e)} name='Term' />
                            </Box>
                        </Grid>
                            )
                    }
                    <Grid  item lg={6}>
                      <Box>
                        <CustomBtn type="button" click={()=>openShoqa()} data=" Open Shoqa " />
                      </Box>
                    </Grid>
                {/* <Grid item lg={6}>
                    <Box p={1}>
                        <Date sx={{width: '100%'}} />
                    </Box>
                </Grid> */}
            </Grid>
            <Grid container>
                <Grid item lg={12}>
                    {/* <Box mt={4}> */}
                    {
                      
                      serverData?.Result && (
                        <>
                          <FormTable data={serverData?.Result} />
                        </>
                      )
                    }
                    {/* </Box> */}
                </Grid>
            </Grid>
        </Box>
    </PageContainer>
  )
}

Shoqa.propTypes = {}

export default Shoqa