import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ShowLoader } from '../../../../redux/actions/loader';
import axios from 'axios';
import { SelectOnObject } from '../../../../components/select/selectOnObject';
import { Main } from '../../../../constant';
import { Box, Grid } from '@mui/material';
import AppVeiw from '../../../../components/report/appveiw';
import SuggestionInput from '../../../../components/suggestionInput/suggestionInput';
import { CustomBtn } from '../../../../components/button/button';
import { MobileAppApi } from '../../../../redux/actions/mobileApp/mobileApp';

const AssignChildren = (props) => {
    const [data, setData] = useState(Main().MobileApp);
    const getfilterdata = data.inputs.filter((item) => item.feildtype !== 'label');
    // const [loadDate, setLoadDate] = useState(DateGlobalConvertor(dayjs(Date.now())))
    const initialInputValues = Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    );
    const [inputValues, setInputValues] = useState(initialInputValues);
    
    const [items , setItems] = useState()
    const dispatch = useDispatch();
    const url = useSelector((state) => state.Api);
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
    const [children , setChildren ] = useState()
    const [btnDisable , setBtnDisable] = useState(false);
    const [getParentSelect , setParentSelect] = useState(-1);


    const [suggestions , setSuggestions] = useState();
    const [selectedStudents, setSelectedStudents] = useState([])


    useEffect(()=>{
        try {
            dispatch(ShowLoader('1'))
            axios.get(url.Showparents, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data.result);
                setItems(res.data.result)
                dispatch(ShowLoader('0'))
              }
            })
            .catch((err) => {
              console.error(err); // Log the error response for debugging
            });
          } catch (err) {
            console.error(err);
          }
    },[])
    const ChangeOnSelect = async(getparam) => {
        setInputValues((oldData)=> ({
            ...oldData,
            [getparam[1]]:getparam[0]
        })
        )
        console.log(getparam)
        setParentSelect(getparam[0])
      }
      
      useEffect(()=>{
        console.log(inputValues)
          if(inputValues.Parent != undefined){
            dispatch(ShowLoader('1'))
          axios.get(url.showchildren + inputValues.Parent,{
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
              },
            },
        )
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data);
                setChildren(res.data)
                setTimeout(()=>{
                  dispatch(ShowLoader('0'))
                },1000)
  
              }
            })
            .catch((err) => {
              console.error(err); // Log the error response for debugging
              dispatch(ShowLoader('0'))
              setChildren('')
            });
          }
  
      },[inputValues.Parent, btnDisable ])


      useEffect(()=>{
          try {
              dispatch(ShowLoader('1'))
              axios.get(url.studentsuggestion, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${userToken}`,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  console.log(res.data.message);
                  setSuggestions(res.data.message);
                  dispatch(ShowLoader('0'))
                }
              })
              .catch((err) => {
                console.error(err); // Log the error response for debugging
              });
            } catch (err) {
              console.error(err);
            }

      },[])

      function suggestionhandleInputChange(e) {
        const newValue = e.target.value;
        if (selectedStudents.includes(newValue)) {
            //alert('Value already exists!');
        } else {
            setSelectedStudents(prevSelectedStudents => [...prevSelectedStudents, newValue]);
        }
        
    }
    function removeStudent(item, ind){
        if (selectedStudents.includes(item)) {
            const updatedStudents = selectedStudents.filter((student, index) => index !== ind);
            setSelectedStudents(updatedStudents);        }
    }
    const submitForm = (event) => {
        event.preventDefault();
        console.log(selectedStudents.length <= 0)
        if(selectedStudents.length <= 0)
        return false
        console.log(inputValues)
        setBtnDisable(true)
        const payload = {...inputValues , Students : selectedStudents}
        dispatch(ShowLoader('1'))
        dispatch(MobileAppApi(url.assignChildren, JSON.parse(sessionStorage.getItem("User_Data")).token, JSON.stringify(payload) ))
        setTimeout(() => {
            // refreshForm();
            // setInputValues(initialInputValues)
            setSelectedStudents([])
            setBtnDisable(false)
            // fetchMobileRegisteredData()
        }, 1000)
    }

    return(
        <>
            <form onSubmit={submitForm}>
                <Grid container>
                    <Grid item lg={6} md={6}>
                        <SelectOnObject items={items} option='name' GetSelectedValue={(e)=>ChangeOnSelect(e)} name='Parent' />
                    </Grid>
                    <Grid item lg={1}></Grid>
                    <Grid item lg={5} md={6}>
                        <SuggestionInput 
                            handleInputChange={suggestionhandleInputChange}
                            Suggestions={suggestions}
                            placeholder='Roll No / Name'
                            name='RollNum'
                            />
                    </Grid>
                </Grid>
                    { selectedStudents.length > 0 ?
                        <>
                        <Grid lg={3} item mt={3} className='selectstd'><label>Selected Student  ټاکل شوی متعلم </label></Grid>
                            <Grid lg={6} item className='selectedStudents'>
                            {selectedStudents.map((value, index) => (
                                <div onClick={(event)=>removeStudent(value, index)} key={value}>Student Roll - {value}</div>
                            ))}
                            </Grid>
                    </>
                    : 
                    null

                    }
                    <Box mt={1}>
                    <br/><br/><br/>
                        <CustomBtn disable={selectedStudents.length <= 0 || getParentSelect == -1} data='Submit' style={{ margin: 'auto', display: 'block', marginTop: '10px' }} />
                    </Box>

            </form>



            {
            children && (
                children.map((item,ind)=>(
                <Box mt={2} key={ind}>
                
                    <AppVeiw data={item.result} />
                    
                </Box>
                )))
            }
            </>
    )
}

export default AssignChildren