import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@emotion/react';
import { MainClass } from '../../../../components/select/classs';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ShowLoader } from '../../../../redux/actions/loader';
import { CustomSelect } from '../../../../components/select/select';
import { CustomBtn } from '../../../../components/button/button';

export default function FormTable(props) {
  console.log(props.data.fetch)
  const theme = useTheme();
  const style = theme.palette;
  const [classes, setClasses] = useState([]);
  const [selectNumbers, setSelectNumbers] = useState([]);
  const [inputValues, setInputValues] = useState({
    RollNum: props?.data?.fetch[0]?.RollNum || '',
    Position: '',
    Class: '', //this to update in student table
    ClassID: '',
    OldClassID: props.data.term.ClassID,
    Grade: '',
    TotalMarks: '',
    TermSrn: props.data.term.TermSrn,
    parentShoqaSrn : props.data.term.Srn
  });

  const url = useSelector((state) => state.Api.updateClass);
  const dispatch = useDispatch();

  // Handle form submission
  const myfunc = async (event, getRec, pos) => {
    const getMarks = getRec.MarksPercentage.split(' ')
    event.preventDefault();
    inputValues.RollNum = getRec.RollNum
    inputValues.Position = pos
    inputValues.ClassID = getRec.ClassID === undefined || getRec.ClassID === '' ? props.data.term.ClassID : getRec.ClassID
    inputValues.Grade = calculateGrade(getRec.MarksPercentage)
    inputValues.TotalMarks = getMarks[0]
    console.log(inputValues)
    // return false
    dispatch(ShowLoader('1'));
    const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;

    try {
      // Uncomment and adjust the request as necessary
      const res = await axios.post(url, inputValues, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        }
      });

      if (res.status === 200) {
        setClasses(res.data.result);
      }

      console.log(inputValues);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      dispatch(ShowLoader('0'));
    }
  };

  useEffect(() => {
    setSelectNumbers(props?.data?.fetch);
  }, [props?.data?.fetch]);

  // Number options for the select element
  const numberOptions = Array.from({ length: 100 }, (_, i) => i);

  // Pass/Fail options
  const passFail = [
    { label: 'کامیاب', value: 1 },
    { label: 'ناکام', value: 0 }
  ];

  // Calculate grade based on percentage
  const calculateGrade = (percentageString) => {
    const matched = percentageString.match(/\((\d+\.\d+)%\)/);
    if (matched) {
      const percentage = parseFloat(matched[1]);
      if (percentage >= 90) return 'ممتاز';
      if (percentage >= 80) return 'جیدجداً';
      if (percentage >= 50) return 'جید';
      if (percentage >= 40) return 'مقبول';
    }
    return 'ناکام';
  };

  // Handle select changes
  const handleSelectChange = (key, getRow) => (event) => {
    console.log(getRow)
    const value = event.target.value;
    setInputValues((prev) => ({
      ...prev,
      [key]: value,
      Grade: calculateGrade(getRow.MarksPercentage),
      RollNum: getRow.RollNum
    }));
  };

  const ChangeOnSelect = (getRow, ind) => (getparam) => {
    console.log(getRow, ind)
    console.log(getparam)
    // props.data.fetch[ind].ClassID = getparam[0] 
    // props.data.fetch[ind].Class = getparam[3] 
    // props.data.term.ClassID
    if (getparam[1] == 'Class') {
      setInputValues((oldData) => ({
        ...oldData,
        ClassID: getparam[0],
        Class : getparam[3],
      })
      )
    }
    else {
      setInputValues((oldData) => ({
        ...oldData,
        [getparam[1]]: getparam[0]
      })
      )
    }
    console.log(inputValues)

  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow sx={{ background: style.primary.light }} id="thead">
            {props?.data?.fetch && Object.keys(props?.data?.fetch[0]).filter(key => !['subjects', 'action', 'Grades', 'SelectGrade'].includes(key)).map(key => (
              <TableCell key={key}>{key}</TableCell>
            ))}
            {props?.data?.fetch && props?.data?.fetch[0].subjects.map((subject) => (
              <TableCell key={subject.SubjectName}>{subject.SubjectName}</TableCell>
            ))}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.data?.fetch && props?.data?.fetch.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {Object.keys(row).filter(key => !['subjects', 'action', 'Grades', 'SelectGrade'].includes(key)).map((key, cellIndex) => (
                <TableCell key={cellIndex}>
                  {key === 'MarksPercentage' ? `${row[key]} ${calculateGrade(row.MarksPercentage)}` : row[key]}
                </TableCell>
              ))}
              {row.subjects.map((subject, subjectIndex) => (
                <TableCell key={subjectIndex}>
                  {subject.Total !== undefined ? subject.Total : 0}
                </TableCell>
              ))}
              <TableCell>
                {row.action ? (
                  <form onSubmit={(event)=>myfunc(event, row, rowIndex+1)}>
                    
                    {
                      props.data.term.TermSrn == 1 && row.shoqaStatus == 0?
                      <MainClass required={true} name="Class"  GetSelectedValue={ChangeOnSelect(row, rowIndex)} />
                      : null
                    }
                    <br />
                      <h1>{`${rowIndex+1}`}</h1>
                    {/* <select className='position passFail' required={true} onChange={handleSelectChange('Position', row)}>
                      {numberOptions.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select> */}
                    {/* <select className='passFail' onChange={handleSelectChange('FailPass')}>
                      {passFail.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select> */}
                    {row.shoqaStatus == 0? 
                      <CustomBtn data='submit' />
                      : null }
                  </form>
                ) : (
                  <span>Full Fill Marks</span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
