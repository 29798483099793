import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import PageContainer from '../../../../components/Container/pageContainer'
import { Input } from '../../../../components/input/input'
import CustomForm from '../../../../components/form/form'
import { Main } from '../../../../constant'
import { useTheme } from '@emotion/react'
import AccessibleTable from '../../../../components/table/table'
import { CustomBtn } from '../../../../components/button/button'
import { CustomSelect } from '../../../../components/select/select'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import SuggestionInput from '../../../../components/suggestionInput/suggestionInput'
import { PostRequest } from '../../../../redux/actions/PostRequest'
import { UserData } from '../../../../hooks/FirstTimeWebSrn/Websrn'

const TeacherAttendance = (props) => {
    const [data , setData ] = useState(Main().TeacherAttendance);
    const [select , setSelect] = useState('');
    const [userToken , setUserToken ] = useState(UserData().token);
    const [tableData , setTableData ] = useState();
    const [getfilterdata , setGetfilterdata ] = useState(data.inputs.filter((item) => item.feildtype !== 'label'));
    const [initialInputValues , setInitialInputValues] = useState(Object.fromEntries(
        getfilterdata
            .map((item) => [item.name, ''])
    ));
    const url = useSelector((state) => state.Api.teachersuggestion);
    const manaulurl = useSelector((state) => state.Api.Teachermaunaulattendence);
    const Teachercurrentdayreport = useSelector((state) => state.Api.Teachercurrentdayreport);
    const [ FilterData , setFilterData ] = useState();

    const theme = useTheme();
    const style = theme.palette.Main.Attendance;

    const [inputValues, setInputValues] = useState(initialInputValues);
    const [ focusData , setFocusData ] = useState(0);
    const [suggestions , setSuggestions] = useState();
    const dispatch = useDispatch();
    

    
    function handleRadioChange(e) {
        setInputValues((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.placeholder,
        }));
    }



  useEffect(()=>{
        setTableData('');
        try {
            axios.get(`${Teachercurrentdayreport}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data.result);
                setTableData(res.data.result);
              }
            })
            .catch((err) => {
              console.error(err); // Log the error response for debugging
            });
          } catch (err) {
            console.error(err);
      }
      
  },[focusData])

      //table data


      
      const ChangeDate = (e , name) =>{
        console.log(e , name)
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: e
    }));
    }



      //form 
      const submitForm = async(e) => {
        e.preventDefault();
        console.log(inputValues)
        // const startDate = new Date(inputValues.startDate);
        // const endDate = new Date(inputValues.endDate);
        const startDate = new Date(inputValues.startDate == '' ? new Date() : inputValues.startDate);
        const endDate = new Date(inputValues.endDate == '' ? new Date() : inputValues.endDate);
  
        // Function to format date as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
  
        // Generate an array of objects representing the date range
        const dateRangeArray = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          dateRangeArray.push({ date: formatDate(currentDate) });
          currentDate.setDate(currentDate.getDate() + 1);
        }
  
        // console.log(dateRangeArray);
        inputValues.getDate = dateRangeArray;
        console.log(inputValues)

          
        await dispatch(PostRequest(manaulurl,userToken,inputValues))
        setFocusData((param) => param + 1);
      }
      console.log(UserData().token)
      useEffect(() => {
    // const userToken = JSON.parse(sessionStorage.getItem('User_Data'))?.token || undefined;
        try {
          if(suggestions == undefined || suggestions == null){
            axios.get(url, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                console.log(res.data.message);
                setSuggestions(res.data.message);
              }
            })
            .catch((err) => {
              console.error(err); // Log the error response for debugging
            });
          }
        } catch (err) {
          console.error(err);
        }
      }, [focusData]);
  
      function handleInputChange(e) {
        console.log(e)
        if(e[0]){  
            const val = e[0].substring(1, e[0].length - 1);
            const string = 'R'+val;
         setInputValues((prevValues) => ({
                ...prevValues,
                [string]: e[1],
            }));
        }
        else{
            setInputValues((prevValues) => ({
                ...prevValues,
                [e.target.name]: e.target.value,
            }));

        }
    }
        

  return (
    <PageContainer title="Student Attendance">
        <Box>
            <form onSubmit={submitForm}>
                    {
                                suggestions && (
                                    <SuggestionInput 
                                    simple='true'
                                    handleInputChange={handleInputChange}
                                    Suggestions={suggestions}
                                    style={style.children}
                                    name={data.text.name}
                                    />

                                )
                            }


                <Grid container>
                <CustomForm data={data.inputs} handleRadioChange={handleRadioChange} ChangeDate={ChangeDate}  handleInputChange={handleInputChange}  />
                </Grid>
                <Grid container>
                    <Grid item lg={6}>
                        <div style={{marginTop:'20px'}}></div>
                        <CustomBtn data='Submit' />
                    </Grid>

                </Grid>

            </form>
        </Box>
      
        <Box mt={5}>
            <Grid container>
                <Grid item lg={10}>
                {
                        tableData &&   (
                            <AccessibleTable  rows={tableData} />
                        )
                    }
                </Grid>
            </Grid>
        </Box>
        
    </PageContainer>
  )
}

TeacherAttendance.propTypes = {}

export default TeacherAttendance